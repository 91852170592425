import { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { preventSubmitOnEnter } from 'lib/utils';
import { trackEvent } from 'lib/analytics';
import { isValid } from 'date-fns';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { apiRequest } from 'lib/API';
import { updateExperience } from 'lib/API/experience';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setNeedsContactInfo, setContactUpdate } from 'app/slices/appSlice';

/* Material UI and other UI Dependencies */
import { Typography, FormControlLabel, Checkbox, CircularProgress, FormLabel } from '@mui/material';
import OnboardingSteps from 'components/OnboardingSteps';
import {
  Box,
  FormControl,
  TextField,
  FormHelperText,
  useMediaQuery,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DateInput from 'components/forms/DateInput';
import { WhiteFilledInput } from 'components/forms/OnboardingContact';
import { TypeSelect } from 'components/forms/experienceWallet/ExperienceWalletForm';
import ExperienceWalletFieldsAutocomplete from 'components/forms/experienceWallet/ExperienceWalletFieldsAutocomplete';
import { pathOr, propOr } from 'ramda';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function isoToLocalized(isoDate) {
  const day = isoDate.substring(8, 10);
  const month = isoDate.substring(5, 7);
  const year = isoDate.substring(0, 4);

  return `${month}/${day}/${year}`;
}

export default function OnboardingExperience() {
  const isSmall = useMediaQuery('(max-width:767px)');
  const [submitting, setSubmitting] = useState(false);
  const [fieldSuggestion, setFieldSuggestion] = useState('');
  const [savingDegree, setSavingDegree] = useState(false);
  const [gettingSkills, setGettingSkills] = useState(false);
  const [socError, setSocError] = useState('');

  const { results: careers } = useSelector((state) => state.careers);

  const intl = useIntl();
  const [fieldOpen, setFieldOpen] = useState(false);
  const dispatch = useDispatch();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();
  const educationOptions = [
    {
      value: '11',
      title: intl.formatMessage({ id: 'forms.onboarding.education.noDiploma' }),
      id: 'education-no-diploma-onboard-dropdown',
    },
    {
      value: '11.5',
      title: intl.formatMessage({ id: 'forms.onboarding.education.someHighSchool' }),
      id: 'education-some-highschool-onboard-dropdown',
    },
    {
      value: '12',
      title: intl.formatMessage({ id: 'forms.onboarding.education.diploma' }),
      id: 'education-diploma-onboard-dropdown',
    },
    {
      value: '13',
      title: intl.formatMessage({ id: 'forms.onboarding.education.someCollege' }),
      id: 'education-some-college-onboard-dropdown',
    },
    {
      value: '14',
      title: intl.formatMessage({ id: 'forms.onboarding.education.assocDegree' }),
      id: 'education-associates-onboard-dropdown',
    },
    {
      value: '16',
      title: intl.formatMessage({ id: 'forms.onboarding.education.bachDegree' }),
      id: 'education-bachelors-onboard-dropdown',
    },
    {
      value: '16.5',
      title: intl.formatMessage({ id: 'forms.onboarding.education.someGraduate' }),
      id: 'education-some-graduate-onboard-dropdown',
    },
    {
      value: '17',
      title: intl.formatMessage({ id: 'forms.onboarding.education.mastersDegree' }),
      id: 'education-masters-onboard-dropdown',
    },
    {
      value: '20',
      title: intl.formatMessage({ id: 'forms.onboarding.education.advancedDegree' }),
      id: 'education-doctoral-onboard-dropdown',
    },
  ];
  const placeholderDict = {
    apprenticeship: intl.formatMessage({ id: 'experience.form.apprenticeshipDescriptionPlaceholder' }),
    assessment: intl.formatMessage({ id: 'experience.form.assessmentDescriptionPlaceholder' }),
    badge: intl.formatMessage({ id: 'experience.form.badgeDescriptionPlaceholder' }),
    course: intl.formatMessage({ id: 'experience.form.courseDescriptionPlaceholder' }),
    degree: intl.formatMessage({ id: 'experience.form.degreeDescriptionPlaceholder' }),
    license: intl.formatMessage({ id: 'experience.form.licenseDescriptionPlaceholder' }),
    certification: intl.formatMessage({ id: 'experience.form.certificationDescriptionPlaceholder' }),
    internship: intl.formatMessage({ id: 'experience.form.internshipDescriptionPlaceholder' }),
    employment: intl.formatMessage({ id: 'experience.form.jobDescriptionPlaceholder' }),
  };

  const initialValues = {
    type: '',
    field: null,
    description: '',
    skills: [],
    start_date: '',
    end_date: '',
    employer_provider: '',
    degree: '',
  };

  const queryClient = useQueryClient();

  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/'],
    refetchOnWindowFocus: false,
  });

  const [isCurrentJob, setIsCurrentJob] = useState(experienceQuery.data?.job_experience[0]?.end_date === null);
  const [isExperienceCurrent, setIsExperienceCurrent] = useState(
    experienceQuery.data?.job_experience[0]?.is_current ?? false
  );

  return (
    <Box sx={{ boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.1)' }}>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3}>
          <Typography align="center" variant="h1" gutterBottom sx={{ fontSize: 24, mt: 1 }}>
            {intl.formatMessage({ id: 'onboarding.title' }, { step: 2, totalSteps: 5 })}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: 222, marginLeft: 'auto', marginRight: 'auto', mt: 4, mb: 1 }}>
          <OnboardingSteps activeStep={2} />
        </Box>
      </div>
      <hr className={layoutStyles.separatorBold} />
      <Box className={styles.onboardingExperience__cardsContainer} sx={{ maxWidth: 500, margin: 'auto' }}>
        <Formik
          initialValues={{
            ...initialValues,
            ...experienceQuery.data?.job_experience[0],
            start_date: experienceQuery.data?.job_experience[0]?.start_date
              ? isoToLocalized(experienceQuery.data?.job_experience[0]?.start_date)
              : '',
            end_date: experienceQuery.data?.job_experience[0]?.end_date
              ? isoToLocalized(experienceQuery.data?.job_experience[0]?.end_date)
              : '',
            field: experienceQuery.data?.job_experience[0]?.fields[0],
            degree: experienceQuery.data?.education[0]?.degree ?? '',
          }}
          validate={(values) => {
            const errors = {};
            const lettersNumbers = /^[^<>]+$/; // eslint-disable-line no-useless-escape
            const startDate = new Date(propOr('', 'start_date', values));
            const endDate = new Date(propOr('', 'end_date', values));

            if (!values.type) {
              errors.type = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            if (!values.field) {
              errors.field = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            if (!values.description) {
              errors.description = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            if (!isValid(startDate)) {
              errors.start_date = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            } else if (startDate < new Date(1950, 0, 1) || startDate > new Date()) {
              errors.start_date = intl.formatMessage({ id: 'onboarding.experience.invalidDate' });
            }
            if (!isCurrentJob && isValid(startDate) && isValid(endDate) && endDate < startDate) {
              errors.end_date = intl.formatMessage({ id: 'experience.form.endDatePrior' });
            }
            if (
              ((values.type === 'employment' && !isCurrentJob) ||
                (values.type !== 'employment' && !isExperienceCurrent)) &&
              !isValid(endDate)
            ) {
              errors.end_date = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            if (!values.employer_provider) {
              errors.employer_provider = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            if (values.employer_provider && !lettersNumbers.test(values.employer_provider)) {
              errors.employer_provider = intl.formatMessage({ id: 'profile.validation.basicPunctiation' });
            }
            if (values.type === 'degree' && !values.degree) {
              errors.degree = intl.formatMessage({ id: 'forms.onboarding.educationRequired' });
            }
            return errors;
          }}
          onSubmit={(values) => {
            values.description = values.description.replace(/[<>]/g, ' ');
            setSubmitting(true);
            const startDate = values.start_date ? new Date(values.start_date) : null;
            const endDate = values.end_date ? new Date(values.end_date) : null;

            const newExperiences = [
              {
                description: values.description,
                start_date: startDate.toISOString().slice(0, 10),
                ...((values.type === 'employment' && !isCurrentJob) || values.type !== 'employment'
                  ? { end_date: endDate ? endDate.toISOString().slice(0, 10) : null }
                  : {}),
                ...(values.type !== 'employment' ? { is_current: isExperienceCurrent } : {}),
                type: values.type,
                skills: values.skills,
                ...(values.field?.soc
                  ? {
                      fields: [{ soc: values?.field?.soc, title: values?.field?.title }],
                    }
                  : {}),
                employer_provider: values.employer_provider,
              },
            ];
            setSubmitting(true);
            apiRequest('PUT', '/users/me/experience/', {
              data: {
                job_experience: newExperiences,
              },
              params: {
                on_boarding: true,
              },
            })
              .then(() => {
                setSubmitting(false);
                queryClient.invalidateQueries({ queryKey: ['/users/me/experience/'] });
                if (values.description) {
                  trackEvent('ONBOARDING_EXPERIENCE_DESCRIPTION', values.description);
                }
                if (values.type) {
                  trackEvent('ONBOARDING_EXPERIENCE_EXPERIENCE_TYPE', values.type);
                }
                if (values.skills?.length || values.field?.soc) {
                  trackEvent('ONBOARDING_EXPERIENCE_SOC');
                }
                history.push(`${urlPrefix}/onboarding/education`);
              })
              .catch((error) => {
                setSubmitting(false);
                if (error.toJSON()?.message === 'Request failed with status code 400') {
                  setFieldSuggestion(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }));
                }
              });
            trackEvent('ONBOARDING_EXPERIENCE_NEXT');
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Typography align="center" variant="h5" component="h2" gutterBottom px={{ xs: 1, md: 6 }}>
                {intl.formatMessage({
                  id: 'onboarding.experience.enterJobExperience',
                })}
              </Typography>
              <Box mb={3} />
              <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
                <FormControl variant="filled" error={touched.type && Boolean(errors.type)}>
                  <FormLabel id="type-select-label" htmlFor="type-select-input">
                    <Typography sx={{ fontWeight: 700 }}>
                      {intl.formatMessage({ id: 'onboarding.experience.shareAnExperience' })}
                      <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                        *
                      </Typography>
                    </Typography>
                  </FormLabel>
                  <TypeSelect
                    onChange={handleChange}
                    onChangeCapture={() => setFieldValue('degree', null)}
                    value={values.type}
                    labelId="type-select-input"
                  />
                  {touched.type && errors.type ? <FormHelperText>{errors.type}</FormHelperText> : null}
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* Description */}
                <FormControl
                  sx={{ display: 'flex', mb: 1, flexDirection: 'column', order: values.type === 'degree' ? 2 : 1 }}
                >
                  <FormLabel htmlFor="experience-detail-onboard-link">
                    <Typography sx={{ fontWeight: 700 }}>
                      {intl.formatMessage({
                        id: values.type === 'degree' ? 'experience.form.descriptionAlt' : 'experience.form.description',
                      })}
                      <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                        *
                      </Typography>
                    </Typography>
                  </FormLabel>
                  <Box>
                    <TextField
                      sx={{
                        '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                        '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                      }}
                      error={touched.description && (Boolean(errors.description) || Boolean(socError))}
                      fullWidth
                      helperText={touched.description && (errors.description || socError)}
                      margin="normal"
                      name="description"
                      disabled={false}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onChangeCapture={() => setSocError('')}
                      onBlurCapture={(e) => {
                        setSocError('');
                        if (e.target.value && values.type === 'employment') {
                          setGettingSkills(true);
                          setFieldOpen(false);
                          apiRequest('GET', '/users/me/skills/socs', {
                            params: { description: e.target.value },
                          })
                            .then((r) => {
                              setFieldValue('skills', r?.skills.slice(0, 5));
                              setFieldSuggestion('');
                              if (pathOr(false, ['soc', 0], r)) {
                                const suggestedSoc = r?.soc[0];
                                const newField = careers.reduce((prev, curr) => {
                                  if (curr.soc === suggestedSoc) {
                                    return curr;
                                  }
                                  return prev;
                                }, {});
                                if (newField.soc) {
                                  setFieldValue('field', newField);
                                }
                              }
                              setGettingSkills(false);
                            })
                            .catch((error) => {
                              setGettingSkills(false);
                              setFieldValue('field', null);
                              const errorMessage = pathOr('', ['response', 'data', 'detail'], error) || error?.message;
                              const matches = errorMessage.match(
                                /Could not a SOC code matching experience item with title/
                              );
                              if (matches) {
                                setSocError(intl.formatMessage({ id: 'onboarding.experience.jobNotFound' }));
                              } else if (error.toJSON()?.message === 'Request failed with status code 400') {
                                setFieldSuggestion(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }));
                              } else {
                                setSocError(intl.formatMessage({ id: 'onboarding.experience.unexpectedError' }));
                              }
                            });
                        }
                      }}
                      type="text"
                      placeholder={values.type ? placeholderDict[values.type] : ''}
                      variant="filled"
                      value={values.description}
                      id="experience-detail-onboard-link"
                      inputProps={{
                        title: intl.formatMessage({
                          id:
                            values.type === 'degree' ? 'experience.form.descriptionAlt' : 'experience.form.description',
                        }),
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </Box>
                </FormControl>
                {/* Field SOC */}
                <Box sx={{ order: values.type === 'degree' ? 3 : 2 }}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({
                      id: values.type === 'degree' ? 'experience.form.fieldsLabelAlt' : 'experience.form.fieldsLabel',
                    })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <ExperienceWalletFieldsAutocomplete
                    id="ield-match-onboard-dropdown"
                    onboarding
                    value={values.field}
                    isDisabled={gettingSkills}
                    onChange={(_, newValue) => {
                      setFieldSuggestion('');
                      setFieldValue('field', newValue);
                      setGettingSkills(true);
                      trackEvent('ONBOARDING_EXPERIENCE_SELECT_FIELD', newValue);
                      apiRequest('GET', '/users/me/skills/socs', {
                        params: { socs: newValue.soc, upvote_skills: 5 },
                      }).then((r) => {
                        setFieldValue('skills', r?.skills.slice(0, 5));
                        setGettingSkills(false);
                      });
                    }}
                    helperText={fieldSuggestion ?? (touched.field && errors.field ? errors.field : '')}
                    error={(touched.field && Boolean(errors.field)) || Boolean(fieldSuggestion)}
                    open={fieldOpen}
                    setOpen={setFieldOpen}
                  />
                </Box>
                {/* Dates */}
                <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column', order: values.type === 'degree' ? 5 : 3 }}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({ id: 'experience.form.duration' })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
                    <FormControl error={touched.start_date && Boolean(errors.start_date)}>
                      <InputLabel htmlFor="start-date-onboard-link" variant="filled">
                        {intl.formatMessage({ id: 'experience.form.startDate' })}
                      </InputLabel>
                      <DateInput
                        name="start_date"
                        customInput={WhiteFilledInput}
                        fullWidth
                        autoComplete="off"
                        value={values.start_date}
                        onChange={handleChange}
                        onKeyDown={preventSubmitOnEnter}
                        onBlur={handleBlur}
                        id="start-date-onboard-link"
                      />
                      {touched.start_date && errors.start_date && <FormHelperText>{errors.start_date}</FormHelperText>}
                    </FormControl>
                    {(values.type === 'employment' && !isCurrentJob) || values.type !== 'employment' ? (
                      <FormControl error={touched.end_date && Boolean(errors.end_date)}>
                        <InputLabel htmlFor="end-date-onboard-link" variant="filled">
                          {intl.formatMessage({ id: 'experience.form.endDate' })}
                        </InputLabel>
                        <DateInput
                          name="end_date"
                          customInput={WhiteFilledInput}
                          fullWidth
                          autoComplete="off"
                          value={values.end_date}
                          onChange={handleChange}
                          onKeyDown={preventSubmitOnEnter}
                          onBlur={handleBlur}
                          id="end-date-onboard-link"
                        />
                        {touched.end_date && errors.end_date && <FormHelperText>{errors.end_date}</FormHelperText>}
                      </FormControl>
                    ) : null}
                  </Box>
                  {values.type === 'employment' ? (
                    <FormControl sx={{ mb: 1, position: 'relative', top: -4 }}>
                      <FormControlLabel
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: 12,
                          },
                        }}
                        label={intl.formatMessage({ id: 'onboarding.experience.currentJob' })}
                        control={
                          <Checkbox
                            sx={{ padding: '0 8px 0 8px' }}
                            color="primary"
                            checked={isCurrentJob}
                            onChange={(e) => {
                              setIsCurrentJob(e.currentTarget.checked);
                            }}
                          />
                        }
                        labelPlacement="end"
                      />
                    </FormControl>
                  ) : (
                    values.type !== '' && (
                      <FormControl sx={{ mb: 1, position: 'relative', top: -4 }}>
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: 12,
                            },
                          }}
                          label={intl.formatMessage({ id: 'onboarding.experience.experienceCurrent' })}
                          control={
                            <Checkbox
                              sx={{ padding: '0 8px 0 8px' }}
                              color="primary"
                              checked={isExperienceCurrent}
                              onChange={(e) => {
                                setIsExperienceCurrent(e.currentTarget.checked);
                              }}
                            />
                          }
                          labelPlacement="end"
                        />
                      </FormControl>
                    )
                  )}
                </Box>
                {/* Employer or Provider */}
                <FormControl
                  sx={{ display: 'flex', mb: 1, flexDirection: 'column', order: values.type === 'degree' ? 4 : 4 }}
                >
                  <FormLabel htmlFor="provider-employer-onboard-link">
                    <Typography sx={{ fontWeight: 700 }}>
                      {intl.formatMessage({ id: 'experience.form.provider' })}
                      <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                        *
                      </Typography>
                    </Typography>
                  </FormLabel>
                  <Box>
                    <TextField
                      sx={{
                        '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                        '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                      }}
                      error={touched.employer_provider && Boolean(errors.employer_provider)}
                      fullWidth
                      helperText={touched.employer_provider && errors.employer_provider}
                      placeholder={intl.formatMessage({ id: 'experience.form.provider' })}
                      margin="normal"
                      name="employer_provider"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      variant="filled"
                      inputProps={{
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                          },
                        },
                        title: intl.formatMessage({ id: 'experience.form.provider' }),
                      }}
                      value={values.employer_provider ?? ''}
                      id="provider-employer-onboard-link"
                    />
                  </Box>
                </FormControl>
                {/* Education Level */}
                <Box
                  sx={{
                    display: values.type === 'degree' ? 'flex' : 'none',
                    mb: 1,
                    flexDirection: 'column',
                    order: values.type === 'degree' ? 1 : 5,
                  }}
                >
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({
                      id:
                        values.type === 'degree'
                          ? 'forms.onboarding.education.addAlt'
                          : 'forms.onboarding.education.add',
                    })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <FormControl variant="filled" error={!!touched.degree && !!errors.degree}>
                    <InputLabel id="education-level-label" htmlFor="education-label">
                      {intl.formatMessage({
                        id: `forms.onboarding.educationLevel.${values.type === 'degree' ? 'alt' : 'titlecase'}`,
                      })}
                    </InputLabel>
                    <Select
                      name="degree"
                      labelId="education-level-label"
                      id="education-level"
                      inputProps={{
                        id: 'education-label',
                      }}
                      value={values.degree}
                      onChange={(e) => {
                        handleChange(e);
                        trackEvent('ONBOARDING_EDUCATION_LEVEL', e.target.value);
                        setSavingDegree(true);
                        updateExperience({ education: [{ degree: e.target.value }] })
                          .then(() => {
                            setSavingDegree(false);
                          })
                          .catch(() => {
                            setSavingDegree(false);
                          });
                      }}
                      onKeyDown={preventSubmitOnEnter}
                      MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                          variant: 'outlined',
                        },
                        sx: {
                          maxHeight: 440,
                          '& .MuiPaper-root': {
                            borderRight: '1px solid #ccc', // Set the border style and color
                            borderBottom: '1px solid #ccc', // Set the border style and color
                            borderLeft: '1px solid #ccc', // Set the border style and color
                          },
                        },
                      }}
                      sx={{
                        '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                        '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                      }}
                    >
                      {educationOptions
                        .filter((opt) => (values.type === 'degree' ? opt.value > 12 : true))
                        .map((eo) => (
                          <MenuItem key={eo.id} value={eo.value} id={eo.id}>
                            {eo.title}
                          </MenuItem>
                        ))}
                    </Select>
                    {!!touched.degree && !!errors.degree && (
                      <FormHelperText>{errors.degree ? intl.formatMessage({ id: errors.degree }) : ''}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  marginTop: isSmall ? 10 : 0,
                  justifyContent: isSmall ? 'space-between' : 'flex-end',
                }}
              >
                <Button
                  color={'secondary'}
                  disabled={submitting}
                  size="large"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    dispatch(setNeedsContactInfo(true));
                    dispatch(setContactUpdate(true));
                    history.push(`${urlPrefix}/onboarding/contact-info`);
                  }}
                  id="back-onboard-step-2-button"
                >
                  {intl.formatMessage({ id: 'forms.onboarding.back' })}
                </Button>

                {gettingSkills ? (
                  <Box mx={2} pt={0.5} display="flex" justifyContent="center">
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <Button
                    color={'secondary'}
                    disabled={submitting || savingDegree || gettingSkills}
                    size="large"
                    type="submit"
                    variant="contained"
                    id="next-onboard-step-2-button"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'forms.onboarding.next' })
                    )}
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
