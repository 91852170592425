import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TextField, Button, Box, Typography, Autocomplete, Grid } from '@mui/material';
import { handleKeyDown } from 'components/forms/DirectDepositEnrollForm';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveMailingAddressInfo } from 'lib/API/ar-ui';

/* i18n */
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';

export default function MailingAddressForm({ mailingAddressData, submitting, setSubmitting, setOpen }) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const saveMailingAddressMutation = useMutation({ mutationFn: (values) => saveMailingAddressInfo(values) });

  const validationSchema = yup.object({
    address_1: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    city: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    state: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .nullable()
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    zip: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .matches(/^[0-9]+$/, intl.formatMessage({ id: 'forms.onboarding.onlyDigits' }))
      .min(5, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits' }, { amount: 5 }))
      .max(5, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits' }, { amount: 5 })),
  });
  const defaultValues = {
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={mailingAddressData ?? defaultValues}
      onSubmit={async (values) => {
        setSubmitting(true);
        saveMailingAddressMutation.mutate(
          { ...mailingAddressData, ...values },
          {
            onSuccess: () => {
              queryClient.setQueryData(['getMailingAddressInfo'], () => ({ ...mailingAddressData, ...values }));
              setSubmitting(false);
              setOpen(false);
            },
            onError: () => {
              enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
              setSubmitting(false);
            },
          }
        );
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 700 }}>
                {intl.formatMessage({ id: 'fileClaim.mailModal.line1' })}
                <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                  *
                </Typography>
              </Typography>
              <TextField
                sx={{
                  '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                  '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                }}
                error={touched.address_1 && Boolean(errors.address_1)}
                fullWidth
                helperText={touched.address_1 && errors.address_1}
                name="address_1"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="filled"
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      opacity: 1,
                    },
                  },
                }}
                value={values.address_1 ?? ''}
              />
            </Box>
            <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 700 }}>
                {intl.formatMessage({ id: 'fileClaim.mailModal.line2' })}
              </Typography>
              <TextField
                sx={{
                  '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                  '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                }}
                error={touched.address_2 && Boolean(errors.address_2)}
                fullWidth
                helperText={touched.address_2 && errors.address_2}
                name="address_2"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="filled"
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      opacity: 1,
                    },
                  },
                }}
                value={values.address_2 ?? ''}
              />
            </Box>
            <Grid container>
              <Grid item xs={12} md={5}>
                <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({ id: 'fileClaim.mailModal.city' })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>

                  <TextField
                    sx={{
                      '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                      '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                    }}
                    error={touched.city && Boolean(errors.city)}
                    fullWidth
                    helperText={touched.city && errors.city}
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="filled"
                    inputProps={{
                      sx: {
                        '&::placeholder': {
                          opacity: 1,
                        },
                      },
                    }}
                    value={values.city ?? ''}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({ id: 'fileClaim.mailModal.state' })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <Box px={1}>
                    <Autocomplete
                      options={stateList}
                      variant="filled"
                      name="state"
                      value={values.state ?? ''}
                      onChange={(event, value) => {
                        setFieldValue('state', value);
                      }}
                      onBlur={handleBlur}
                      fullWidth
                      sx={{
                        '& .MuiInputBase-root:not(.Mui-disabled)': {
                          backgroundColor: 'white',
                        },
                        '& .MuiInputBase-root': {
                          paddingTop: '10px!important',
                          paddingBottom: '2px!important',
                        },
                        '& .MuiFilledInput-input': {
                          fontSize: 12,
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={intl.formatMessage({ id: 'experience.form.fieldsPlaceholder' })}
                          inputProps={{
                            ...params.inputProps,
                            'aria-label': 'fields',
                          }}
                          helperText={touched.state && errors.state}
                          sx={{
                            '& input::placeholder': {
                              color: 'rgba(0,0,0,0.87)',
                              opacity: 1,
                            },
                          }}
                          error={touched.state && Boolean(errors.state)}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({ id: 'fileClaim.mailModal.zipCode' })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>

                  <TextField
                    sx={{
                      '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                      '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                    }}
                    error={touched.zip && Boolean(errors.zip)}
                    fullWidth
                    helperText={touched.zip && errors.zip}
                    name="zip"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="filled"
                    onKeyDown={handleKeyDown}
                    inputProps={{
                      sx: {
                        '&::placeholder': {
                          opacity: 1,
                        },
                      },
                      maxLength: 5,
                    }}
                    value={values.zip ?? ''}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Button type="button" onClick={() => setOpen(false)} variant="outlined" disabled={submitting}>
                {intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}
              </Button>
              <LoadingButton type="submit" variant="contained" loading={submitting} sx={{ ml: 1 }}>
                {intl.formatMessage({ id: 'fileClaim.mailModal.save' })}
              </LoadingButton>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

MailingAddressForm.propTypes = {
  mailingAddressData: PropTypes.object,
  submitting: PropTypes.bool,
  setSubmitting: PropTypes.func,
  setOpen: PropTypes.func,
};

const stateList = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];
