/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';
import { styled } from '@mui/material/styles';

/* Styles */
import styles from 'styles/layout.module.scss';
import cssVars from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import { Container, Typography } from '@mui/material';
import ScrollToTopButton from 'components/ScrollToTopRound';
import { Box, Grid } from '@mui/material';
import Link from '@mui/material/Link';
import { grey } from '@mui/material/colors';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const FooterLink = styled(Link)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
  lineHeight: '24px',
  paddingBottom: 4,
  width: '100%',
  '&:active': { color: grey[100] },
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
  },
}));

const Footer = () => {
  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);
  if (cssVars.stateSealHref) {
    cssVars.stateSealHref = cssVars.stateSealHref.replace(/['"]+/g, '');
  }

  return (
    <Box
      sx={{
        color: cssVars.white,
        paddingTop: '26px',
        paddingBottom: '34px',
        position: 'relative',
        bgcolor: 'secondary.dark',
      }}
    >
      <Container maxWidth="lg">
        <Grid container className={styles.footerContent} spacing={0}>
          <Grid item xs={12} sm={6} md={3} sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}>
            <Box display="flex" px={4} py={1} alignItems="center">
              <img
                src="/images/ar-state-gov-logo.png"
                style={{ width: 130 }}
                alt={intl.formatMessage({ id: 'homepage.imgAlt.arState' })}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box display="flex" justifyContent="space-around" px={4} pt={2} pb={1}>
              <Grid container>
                <Grid item xs={12} className={styles.footerText} sx={{ mb: { xs: 1, md: 1.5 } }}>
                  <Typography variant="h4" component="h2" style={{ color: cssVars.white }}>
                    {intl.formatMessage({ id: 'layout.footer.aboutAR' })}
                  </Typography>
                </Grid>
                <FooterLink
                  rel="noreferrer noopener"
                  href={`${urlPrefix}/about`}
                  target="_blank"
                  onClick={() => trackEvent('FOOTER_ABOUT')}
                  id="about-launch-footer-public-link"
                >
                  <FormattedMessage id="layout.footer.learnAbout" />
                </FooterLink>
                <FooterLink
                  rel="noreferrer noopener"
                  href={`${urlPrefix}/credits-and-citations`}
                  target="_blank"
                  onClick={() => trackEvent('FOOTER_CREDITS')}
                  id="credits-footer-public-link"
                >
                  <FormattedMessage id="layout.footer.credits" />
                </FooterLink>
                <Grid item xs={12} className={styles.footerText}>
                  <Link
                    rel="noreferrer noopener"
                    href={`${urlPrefix}/terms-of-use`}
                    target="_blank"
                    onClick={() => trackEvent('FOOTER_TERMS')}
                  >
                    <FormattedMessage id="layout.footer.privacy" />
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ pl: { md: 3.5 }, mt: { sm: 3, md: 0 } }}>
            <Box display="flex" justifyContent="space-around" px={4} pt={2} pb={1}>
              <Grid container>
                <Grid item xs={12} className={styles.footerText} sx={{ mb: { xs: 1, md: 1.5 } }}>
                  <Typography variant="h4" component="h2" style={{ color: cssVars.white }}>
                    {intl.formatMessage({ id: 'layout.footer.contact' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={styles.footerText}>
                  <Typography sx={{ color: grey[100], '&:active': { color: grey[100] }, fontSize: 14 }}>
                    {intl.formatMessage({ id: 'layout.footer.reemploymentQuestions' })}
                  </Typography>
                </Grid>
                <Typography
                  sx={{
                    pb: 0.5,
                    color: grey[100],
                    fontSize: 14,
                    textAlign: { xs: 'center', sm: 'left' },
                    width: '100%',
                  }}
                >
                  1-844-908-2178
                </Typography>
                <Grid item xs={12} className={styles.footerText}>
                  <Typography sx={{ color: grey[100], '&:active': { color: grey[100] }, fontSize: 14 }}>
                    {intl.formatMessage({ id: 'layout.footer.launchQuestions' })}
                  </Typography>
                </Grid>
                <FooterLink
                  rel="noreferrer noopener"
                  href={'https://transform.ar.gov/secretarys-office/ardata/launch-support-request-form/'}
                  target="_blank"
                >
                  <FormattedMessage id="layout.footer.supportForm" />
                </FooterLink>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ mt: { sm: 3, md: 0 } }}>
            <Box display="flex" flexDirection="column" px={4} pt={2} pb={1}>
              <Grid item xs={12} className={styles.footerText} sx={{ mb: { xs: 1, md: 1.5 } }}>
                <Typography variant="h4" component="h2" style={{ color: cssVars.white }}>
                  {intl.formatMessage({ id: 'layout.footer.supportServices' })}
                </Typography>
              </Grid>
              <FooterLink
                rel="noreferrer noopener"
                href="https://myardashboard.arkansas.gov/workforce.html"
                target="_blank"
                onClick={() => trackEvent('FOOTER_DASHBOARD')}
                id="workforce-dashboard-footer-public-link"
              >
                <FormattedMessage id="layout.footer.arWorkforceDashboard" />
              </FooterLink>
              <FooterLink
                rel="noreferrer noopener"
                href="https://myarciviform.arkansas.gov/programs"
                target="_blank"
                id="civiform-footer-public-link"
              >
                <FormattedMessage id="layout.footer.civiFormLink" />
              </FooterLink>
              <FooterLink
                rel="noreferrer noopener"
                href="https://www.goodwillar.org/career-services"
                target="_blank"
                id="goodwill-career-footer-public-link"
              >
                <FormattedMessage id="layout.footer.goodWillCareer" />
              </FooterLink>
              <FooterLink
                rel="noreferrer noopener"
                href="https://www.goodwillar.org/reentry"
                target="_blank"
                id="goodwill-reentry-footer-public-link"
              >
                <FormattedMessage id="layout.footer.goodWillReentry" />
              </FooterLink>
              <FooterLink
                rel="noreferrer noopener"
                href="https://portal.arkansas.gov/myar/"
                target="_blank"
                id="gov2go-footer-publich-link"
              >
                <FormattedMessage id="layout.footer.gov2go" />
              </FooterLink>
            </Box>
          </Grid>
        </Grid>
        <ScrollToTopButton />
      </Container>
    </Box>
  );
};

export default Footer;
