import type { FC } from 'react';
import { useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';

import qr from 'assets/images/doors-inspection-checked-qrcode.png';
import lcwLogo from 'assets/images/learner-credential-wallet.png';
import velocityLogo from 'assets/images/velocity-wallet.png';

type Props = {
  onClose: () => void;
};

type PageProps = {
  onClick: (next?: FC<PageProps>) => void;
};

export const ConnectCredWalletDialog: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const [PageComponent, setPageComponent] = useState(() => SelectionPage);

  return (
    <>
      <DialogContent>
        <PageComponent onClick={(next) => (next ? setPageComponent(() => next) : onClose())} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {intl.formatMessage({ id: 'layout.hamburger.close' })}
        </Button>
      </DialogActions>
    </>
  );
};

const SelectionPage: FC<PageProps> = ({ onClick }) => {
  const intl = useIntl();
  return (
    <>
      <Typography sx={{ fontWeight: 700, height: '1.5rem' }}>
        {intl.formatMessage({ id: 'experience.wallet.selection.heading' })}
      </Typography>

      <Box sx={{ maxWidth: 400, pt: 2.2 }}>
        <Typography sx={{ fontWeight: 700 }}>
          {intl.formatMessage({ id: 'experience.wallet.selection.line1' })}
        </Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>
          {intl.formatMessage({ id: 'experience.wallet.selection.line2' })}
        </Typography>
      </Box>

      <Box sx={{ maxWidth: 400, pt: 2.2 }}>
        <Typography sx={{ fontWeight: 700 }}>
          {intl.formatMessage({ id: 'experience.wallet.selection.line3' })}
        </Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>
          {intl.formatMessage({ id: 'experience.wallet.selection.line4' })}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', pt: 4 }}>
        <Typography sx={{ fontWeight: 700, height: '1.5rem', pb: 2.2 }}>Select the wallet you currently use</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ cursor: 'pointer' }}>
              <CardActionArea>
                <CardContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Box sx={{ cursor: 'pointer' }}>
                    <img src={lcwLogo} alt="LCW logo" style={{ height: '60px', border: 0 }} />
                  </Box>
                  <Typography sx={{ fontWeight: 700, fontSize: '0.85rem' }}>Learner Credential Wallet</Typography>
                  <Typography sx={{ fontSize: '0.85rem' }}>Digital Credential Consortium</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card variant="outlined" sx={{ cursor: 'pointer' }}>
              <CardActionArea onClick={() => onClick(VelocityPage)}>
                <CardContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Box sx={{ cursor: 'pointer' }}>
                    <img src={velocityLogo} alt="Velocity logo" style={{ height: '60px', border: 0 }} />
                  </Box>
                  <Typography sx={{ fontWeight: 700, fontSize: '0.85rem' }}>Velocity Career Wallet</Typography>
                  <Typography sx={{ fontSize: '0.85rem' }}>Verifiable Private Credentials</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const VelocityPage: FC<PageProps> = ({ onClick }) => {
  const intl = useIntl();

  return (
    <>
      <Typography sx={{ fontWeight: 700, height: '1.5rem' }}>
        {intl.formatMessage({ id: 'experience.wallet.selection.heading' })}
      </Typography>

      <Box sx={{ maxWidth: 400, pt: 2.2 }}>
        <Typography sx={{ fontWeight: 700 }}>
          {intl.formatMessage({ id: 'experience.wallet.selection.line1' })}
        </Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>
          {intl.formatMessage({ id: 'experience.wallet.selection.line2' })}
        </Typography>
      </Box>

      <Box sx={{ maxWidth: 400, pt: 2.2 }}>
        <Typography sx={{ fontWeight: 700 }}>
          {intl.formatMessage({ id: 'experience.wallet.selection.line3' })}
        </Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>
          {intl.formatMessage({ id: 'experience.wallet.selection.line4' })}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <img src={qr} alt="Velocity logo" style={{ height: '300px', border: 0 }} />

        <Button
          color="primary"
          size="large"
          variant="contained"
          sx={{ textTransform: 'none', px: '2.5rem' }}
          onClick={() => onClick(FinalPage)}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

const FinalPage: FC<PageProps> = ({ onClick }) => {
  const intl = useIntl();

  return (
    <>
      <Typography sx={{ fontWeight: 700, height: '1.5rem' }}>
        {intl.formatMessage({ id: 'experience.wallet.confirmation.heading' })}
      </Typography>

      <Box sx={{ maxWidth: 400, pt: 2.2 }}>
        <Typography sx={{ fontWeight: 700 }}>
          {intl.formatMessage({ id: 'experience.wallet.confirmation.line1' })}
        </Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>
          {intl.formatMessage({ id: 'experience.wallet.confirmation.line2' })}
        </Typography>
      </Box>
      <Box sx={{ maxWidth: 400, pt: 2.2 }}>
        <Typography sx={{ fontWeight: 700 }}>
          {intl.formatMessage({ id: 'experience.wallet.confirmation.line3' })}
        </Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>
          {intl.formatMessage({ id: 'experience.wallet.confirmation.line4' })}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', pt: 2.2 }}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          sx={{ textTransform: 'none', px: '2.5rem', mb: '1rem' }}
          onClick={() => onClick()}
        >
          {intl.formatMessage({ id: 'experience.wallet.confirmation.button' })}
        </Button>
        <Typography sx={{ fontSize: '0.875rem' }}>
          {intl.formatMessage({ id: 'experience.wallet.confirmation.line5' })}
        </Typography>
      </Box>
    </>
  );
};
