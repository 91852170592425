import { Box, Card, CardContent, CardHeader, lighten, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';

/* Icons */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

/* i18n */
import { useIntl } from 'react-intl';

export default function DebitCardCard({ payment_type }) {
  const intl = useIntl();

  const headerContent = (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '33px' }}>
      {payment_type !== 'direct_deposit' && <CheckCircleIcon sx={{ color: 'success.main', fontSize: 22 }} />}{' '}
      {payment_type === 'direct_deposit' && <CancelOutlinedIcon sx={{ color: 'error.main', fontSize: 22 }} />}{' '}
      <Typography
        sx={{ color: payment_type !== 'direct_deposit' ? 'success.main' : 'error.main', fontWeight: 700, pl: 0.5 }}
      >
        {intl.formatMessage({
          id:
            payment_type !== 'direct_deposit'
              ? 'fileClaim.bankingModal.currentMethod'
              : 'fileClaim.bankingModal.notInUse',
        })}
      </Typography>
    </Box>
  );

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardHeader
        title={headerContent}
        sx={{
          py: 1,
          bgcolor: (theme) =>
            lighten(payment_type !== 'direct_deposit' ? theme.palette.success.main : theme.palette.error.main, 0.95),
        }}
      />
      <CardContent sx={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: grey['300'] }}>
        <Typography variant="h3" sx={{ textAlign: 'center', pt: 2.5 }}>
          {intl.formatMessage({ id: 'fileClaim.bankingModal.debitCard' })}
        </Typography>
      </CardContent>
    </Card>
  );
}

DebitCardCard.propTypes = {
  payment_type: PropTypes.string,
};
