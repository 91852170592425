import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme from 'styles/ui-theme';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Fragment, useEffect, useState } from 'react';
import YesNoRadioGroupField from './inputs/YesNoRadioGroupField';
import DatePickerField from './inputs/DatePickerField';
import SelectField from './inputs/SelectField';
import NumericInputField from './inputs/NumericInputField';
import ContinuedClaimProgressTracker from './ContinuedClaimProgressTracker';
import { useMutation } from '@tanstack/react-query';
import { saveContinuousClaimRecord } from 'lib/API';
import { useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import FraudWarningDialog from './FraudWarningDialog';
import { useSnackbar } from 'notistack';
import ContinuedClaimConfirmation from './ContinuedClaimConfirmation';
import CONTINUED_CLAIM_QUESTIONS from 'lib/arknet/continuedClaimQuestions';

function processAnswer(answer) {
  if (answer === 'yes') return 'Y';
  if (answer === 'no') return 'N';
  return answer;
}

const processClaimRecord = async (payload, answer) => {
  const response = await saveContinuousClaimRecord({
    ...payload,
    [answer instanceof Date ? 'date_input' : 'variable_input']: processAnswer(answer),
  });
  return response.next_question_id;
};

const validationSchemas = {
  1005001: Yup.string().required('Required'),
  1005011: Yup.string().required('Required'),
  1005007: Yup.string().required('Required'),
};

const ComponentGenerator = ({ question, errors, touched, disabled, weekStart, weekEnd, isLoading }) => {
  const intl = useIntl();
  switch (question.inputType) {
    case 'yesNoRadioGroup':
      return (
        <YesNoRadioGroupField
          name={question.id}
          label={
            question.richLabel
              ? intl.formatMessage(
                  { id: question.label },
                  {
                    strong: (chunks) => <strong>{chunks}</strong>,
                    weekStart,
                    weekEnd,
                  }
                )
              : question.label[intl.locale]
          }
          locale={intl.locale}
          disabled={disabled}
          horizontalLayoutEnabled={question?.horizontalLayoutEnabled}
          isLoading={isLoading}
        />
      );
    case 'numericInt':
      return (
        <NumericInputField
          name={question.id}
          label={question.label[intl.locale]}
          helperText={question?.helperText?.[intl.locale]}
          error={touched[question.id] && Boolean(errors[question.id])}
          locale={intl.locale}
          disabled={disabled}
        />
      );
    case 'numericFloat':
      return (
        <NumericInputField
          name={question.id}
          label={question.label[intl.locale]}
          helperText={question?.helperText?.[intl.locale]}
          error={touched[question.id] && Boolean(errors[question.id])}
          locale={intl.locale}
          decimalScale={2}
          disabled={disabled}
        />
      );
    case 'date':
      return (
        <DatePickerField
          name={question.id}
          label={question.label[intl.locale]}
          helperText={question?.helperText?.[intl.locale]}
          error={touched[question.id] && Boolean(errors[question.id])}
          locale={intl.locale}
          disabled={disabled}
        />
      );
    case 'select':
      return (
        <SelectField
          name={question.id}
          label={question.label[intl.locale]}
          helperText={question?.helperText?.[intl.locale]}
          error={touched[question.id] && Boolean(errors[question.id])}
          options={question.options}
          locale={intl.locale}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};
ComponentGenerator.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  disabled: PropTypes.bool,
  richLabel: PropTypes.bool,
  weekStart: PropTypes.string,
  weekEnd: PropTypes.string,
  isLoading: PropTypes.bool,
};

const POSSIBLE_QUESTION_IDS = [
  1005001,
  1005011,
  1005007,
  1005027,
  1005035,
  1005201,
  1005022,
  1005101,
  1005017,
  1005002,
  1005202,
  1005102,
  1005003,
  1005203,
  1005103,
  1005004,
  1005104,
  1005005,
  1005105,
  1005015,
  1005115,
  1005025,
  1005125,
  1005045,
  1005145,
  1005051,
  2005001,
  2005011,
  3005001,
  3005011,
  3005002,
  3005021,
  3005003,
  3005031,
  4005001,
  4005011,
  // Skipping these intentionally
  // 5005001,
  // 5005011,
  // 6005001,
  // 6005011,
  6005002,
  'C',
];

const WAGES_QUESTION_IDS = ['1005003', '1005004', '1005005', '1005015', '1005025', '1005045'];

const calculateProgress = (currentQuestionId) => {
  const index = POSSIBLE_QUESTION_IDS.findIndex((q) => q == currentQuestionId);
  if (index === -1) return 0; // If not found, return 0 progress
  return (index / POSSIBLE_QUESTION_IDS.length) * 100;
};

function ContinuedClaim() {
  const { selectedClaim } = useSelector((state) => state.ui);
  const { claim_week_activities_completed } = useSelector((state) => state.worksearch.status);

  const [currentQuestionId, setCurrentQuestionId] = useState(
    selectedClaim?.initiatedClaim?.FirstQuestionID ?? '1005001'
  );
  const [submittedQuestionIds, setSubmittedQuestionIds] = useState([]);
  const [fraudWarningDialogAnswer, setFraudWarningDialogAnswer] = useState('');
  const [isSavingClaimRecord, setIsSavingClaimRecord] = useState(false);
  const [isLastQuestionAnswered, setIsLastQuestionAnswered] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const locale = intl.locale;
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const progress = calculateProgress(currentQuestionId);

  const { enqueueSnackbar } = useSnackbar();
  const updateContinuedClaimMutation = useMutation({ mutationFn: (values) => saveContinuousClaimRecord(values) });

  const parsedWeekStart = selectedClaim.weekStart
    ? format(parse(selectedClaim.weekStart, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')
    : '';
  const parsedWeekEnd = selectedClaim.weekEnd
    ? format(parse(selectedClaim.weekEnd, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')
    : '';

  const reusablePayload = {
    claim_call_number: selectedClaim?.initiatedClaim?.ClaimCallNum,
    question_id: currentQuestionId ? currentQuestionId : selectedClaim?.initiatedClaim?.FirstQuestionID,
    bwe: selectedClaim?.initiatedClaim?.WeekEndDate,
    question_type: 'A',
  };

  const wagesQuestionsPreviewStartIndex = WAGES_QUESTION_IDS.includes(currentQuestionId)
    ? WAGES_QUESTION_IDS.indexOf(currentQuestionId) + 1
    : 0;

  const wagesFilteredQuestionsPreview = WAGES_QUESTION_IDS.slice(wagesQuestionsPreviewStartIndex).filter(
    (questionId) => !submittedQuestionIds.includes(questionId)
  );

  function handleChangeFraudWarningAnswer(answer) {
    setFraudWarningDialogAnswer(answer);
  }

  useEffect(() => {
    if (!selectedClaim.weekEnd) {
      history.push(`${urlPrefix}/ui-dashboard`);
    }
  }, [selectedClaim]);

  return (
    <ThemeProvider
      theme={{
        palette: { ...theme.palette },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
              },
            },
          },
          MuiFormControl: {
            defaultProps: {
              fullWidth: true,
            },
          },
          MuiInputBase: {
            defaultProps: {
              fullWidth: true,
            },
            styleOverrides: {
              root: {
                // Remove up/down arrow dials from input
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none',
                },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                '&.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.55)',
                },
              },
            },
          },
        },
      }}
    >
      <Container maxWidth="lg">
        {isLastQuestionAnswered ? (
          <ContinuedClaimConfirmation />
        ) : (
          <Box sx={{ py: 3.75 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: { md: 'flex-end' },
                flexDirection: { xs: 'column', md: 'row' },
                mb: 2,
              }}
            >
              <Typography variant="h1">
                <AssignmentTwoToneIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
                {intl.formatMessage({ id: 'continuedClaim.title' })}
              </Typography>

              <Button variant="outlined" onClick={() => setCancelDialogOpen(true)}>
                {intl.formatMessage({ id: 'continuedClaim.cancel' })}
              </Button>
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Alert severity="warning">
              <AlertTitle color="inherit" sx={{ fontWeight: 'bold' }}>
                {intl.formatMessage({ id: 'continuedClaim.cautionAlert.title' })}
              </AlertTitle>
              {intl.formatMessage({ id: 'continuedClaim.cautionAlert.body' })}
            </Alert>

            <Container maxWidth="sm" disableGutters>
              <ContinuedClaimProgressTracker title={`${parsedWeekStart} – ${parsedWeekEnd}`} value={progress} />

              <Formik
                initialValues={{}}
                validationSchema={
                  currentQuestionId
                    ? Yup.object({ [currentQuestionId]: validationSchemas[currentQuestionId] })
                    : Yup.object()
                }
                enableReinitialize
                onSubmit={async (values) => {
                  setIsSavingClaimRecord(true);
                  updateContinuedClaimMutation.mutate(
                    {
                      ...reusablePayload,
                      // TODO: Document this
                      ...(currentQuestionId.trim() === 'C' && { question_type: 'C', question_id: 'C' }),
                      [values[currentQuestionId] instanceof Date ? 'date_input' : 'variable_input']: processAnswer(
                        values[currentQuestionId]
                      ),
                    },
                    {
                      onSuccess: async (onSucessValues) => {
                        const questions = {
                          WORKSEARCH: '5005001',
                          WORKSEARCH_CONFIRMATION: '5005011',
                          MAILING_ADDRESS: '6005001',
                          MAILING_ADDRESS_CONFIRMATION: '6005011',
                        };

                        if (Object.values(questions).includes(onSucessValues.next_question_id)) {
                          try {
                            let nextQuestionId = onSucessValues.next_question_id;
                            if (questions.WORKSEARCH === nextQuestionId) {
                              nextQuestionId = await processClaimRecord(
                                { ...reusablePayload, question_id: nextQuestionId },
                                claim_week_activities_completed >= 5 ? 'Y' : 'N'
                              );
                            }
                            if (nextQuestionId === questions.WORKSEARCH_CONFIRMATION) {
                              nextQuestionId = await processClaimRecord(
                                { ...reusablePayload, question_id: nextQuestionId },
                                'N'
                              );
                            }
                            if (nextQuestionId === questions.MAILING_ADDRESS) {
                              nextQuestionId = await processClaimRecord(
                                { ...reusablePayload, question_id: nextQuestionId },
                                'N'
                              );
                            }
                            if (nextQuestionId === questions.MAILING_ADDRESS_CONFIRMATION) {
                              nextQuestionId = await processClaimRecord(
                                { ...reusablePayload, question_id: nextQuestionId },
                                'N'
                              );
                            }

                            setSubmittedQuestionIds((prev) => prev.concat(currentQuestionId));
                            setCurrentQuestionId(nextQuestionId);
                          } catch (error) {
                            setCurrentQuestionId(values.next_question_id);
                            enqueueSnackbar({
                              variant: 'error',
                              message: intl.formatMessage({ id: 'uiDashboard.unknownError' }),
                            });
                          } finally {
                            setIsSavingClaimRecord(false);
                          }
                        } else if (currentQuestionId === 'C') {
                          setIsLastQuestionAnswered(true);
                        } else {
                          setSubmittedQuestionIds((prev) => prev.concat(currentQuestionId));
                          setCurrentQuestionId(onSucessValues.next_question_id);
                          setIsSavingClaimRecord(false);
                        }
                      },
                      onError: () => {
                        setIsSavingClaimRecord(false);
                        enqueueSnackbar({
                          variant: 'error',
                          message: intl.formatMessage({ id: 'uiDashboard.unknownError' }),
                        });
                      },
                    }
                  );
                }}
              >
                {({ values, errors, touched, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper sx={{ mt: 3, p: { xs: 2, md: 3 } }}>
                      <Stack spacing={1}>
                        {/* Render all previous answers as disabled fields */}
                        {submittedQuestionIds.map((field) => (
                          <Fragment key={field}>
                            {CONTINUED_CLAIM_QUESTIONS[field]?.divider && <Divider />}
                            {Boolean(CONTINUED_CLAIM_QUESTIONS[field]?.headerText) && (
                              <Typography gutterBottom>
                                {CONTINUED_CLAIM_QUESTIONS[field]?.headerText[locale]}
                              </Typography>
                            )}

                            <ComponentGenerator
                              question={CONTINUED_CLAIM_QUESTIONS[field]}
                              errors={{}}
                              touched={{}}
                              disabled
                              weekStart={parsedWeekStart}
                              weekEnd={parsedWeekEnd}
                            />
                          </Fragment>
                        ))}

                        {CONTINUED_CLAIM_QUESTIONS[currentQuestionId]?.divider && <Divider key="active" />}

                        {Boolean(CONTINUED_CLAIM_QUESTIONS[currentQuestionId]?.headerText) && (
                          <Typography gutterBottom>
                            {CONTINUED_CLAIM_QUESTIONS[currentQuestionId]?.headerText[locale]}
                          </Typography>
                        )}

                        {(!fraudWarningDialogAnswer || fraudWarningDialogAnswer === 'agree') && (
                          <ComponentGenerator
                            key={currentQuestionId}
                            question={CONTINUED_CLAIM_QUESTIONS[currentQuestionId]}
                            errors={errors}
                            touched={touched}
                            weekStart={parsedWeekStart}
                            weekEnd={parsedWeekEnd}
                            disabled={isSavingClaimRecord}
                            isLoading={isSavingClaimRecord}
                          />
                        )}
                      </Stack>

                      {CONTINUED_CLAIM_QUESTIONS[currentQuestionId].inputType !== 'yesNoRadioGroup' && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!values[currentQuestionId] || errors[currentQuestionId] || isSavingClaimRecord}
                          sx={{ mt: 2 }}
                        >
                          {intl.formatMessage({ id: 'continuedClaim.questions.next' })}
                        </Button>
                      )}

                      {/* Renders wages questions preview */}
                      {(currentQuestionId === '1005002' || submittedQuestionIds.includes('1005002')) &&
                        wagesFilteredQuestionsPreview.map((questionId) => (
                          <Box key={questionId + 'preview'} mt={1}>
                            <ComponentGenerator
                              question={CONTINUED_CLAIM_QUESTIONS[questionId]}
                              errors={{}}
                              touched={{}}
                              disabled
                              weekStart={parsedWeekStart}
                              weekEnd={parsedWeekEnd}
                            />
                          </Box>
                        ))}

                      <LinearProgress
                        sx={{
                          mt: 1,
                          visibility: isSavingClaimRecord ? 'visible' : 'hidden',
                        }}
                      />

                      {fraudWarningDialogAnswer === 'disagree' && (
                        <Alert severity="error">
                          <AlertTitle>
                            {intl.formatMessage({ id: 'continuedClaim.fraudWarningDisagreeAlert.title' })}
                          </AlertTitle>
                          <Typography>
                            {intl.formatMessage({ id: 'continuedClaim.fraudWarningDisagreeAlert.subtitle' })}
                          </Typography>
                          <Button
                            component={Link}
                            to="/ui-dashboard"
                            sx={{ mt: 1 }}
                            variant="contained"
                            color="secondary"
                            startIcon={<ArrowBackIcon />}
                          >
                            {intl.formatMessage({ id: 'continuedClaim.fraudWarningDisagreeAlert.cta' })}
                          </Button>
                        </Alert>
                      )}
                    </Paper>
                  </form>
                )}
              </Formik>

              <FraudWarningDialog
                open={currentQuestionId && currentQuestionId === 'C' && !fraudWarningDialogAnswer}
                handleChangeFraudWarningAnswer={handleChangeFraudWarningAnswer}
              />
              <CancelDialog
                showPrompt={cancelDialogOpen}
                handleCancel={() => setCancelDialogOpen(false)}
                handleOk={() => history.push(`${urlPrefix}/ui-dashboard`)}
              />
            </Container>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
}

function CancelDialog({ showPrompt, handleOk, handleCancel }) {
  const intl = useIntl();
  return (
    <Dialog maxWidth="xs" open={showPrompt} fullWidth onClose={handleCancel} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">
        {intl.formatMessage({ id: 'continuedClaim.leavingDialog.title' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'continuedClaim.leavingDialog.body' })}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel}>
          {intl.formatMessage({ id: 'leavingDialog.stay' })}
        </Button>
        <Button variant="contained" onClick={handleOk} color="primary">
          {intl.formatMessage({ id: 'leavingDialog.leave' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
CancelDialog.propTypes = {
  showPrompt: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
export default ContinuedClaim;
