import { useEffect, useState } from 'react';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { getMailingAddressInfo } from 'lib/API';

/* Material UI and other UI Dependencies */
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Divider,
} from '@mui/material';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import MailingAddressForm from 'components/forms/MailingAddressForm';
import MailingAddressCard from 'components/MailingAddressCard';

import TimeMe from 'timeme.js';
import { useQuery } from '@tanstack/react-query';

const UpdateMailingAddress = () => {
  const [submitting, setSubmitting] = useState(false);
  const [mailDialogOpen, setMailDialogOpen] = useState(false);

  const intl = useIntl();

  const { data: mailingAddressData, isFetching } = useQuery({
    queryFn: () => getMailingAddressInfo({}),
    queryKey: ['getMailingAddressInfo'],
    retry: false,
  });

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'recommendations.updateMailingAddress' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('UPDATE_MAILING_ADDRESS');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  if (isFetching) {
    return (
      <Box mt={6} mb={2.5} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 6, mb: 2.5 }}>
        <Typography variant="h1">
          <MailOutlinedIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
          {intl.formatMessage({ id: 'recommendations.mailingAddress' })}
        </Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Grid container>
        <Grid item xs={12} sm={9} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          {mailingAddressData && (
            <MailingAddressCard
              title={intl.formatMessage({ id: 'fileClaim.mailModal.title' })}
              mailingAddressData={mailingAddressData}
              callBack={() => setMailDialogOpen(true)}
            />
          )}
        </Grid>
      </Grid>
      <Dialog open={mailDialogOpen} onClose={() => setMailDialogOpen(false)}>
        <DialogTitle>{intl.formatMessage({ id: 'fileClaim.mailModal.title' })}</DialogTitle>
        <DialogContent>
          <MailingAddressForm
            mailingAddressData={mailingAddressData}
            submitting={submitting}
            setSubmitting={setSubmitting}
            setOpen={setMailDialogOpen}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default UpdateMailingAddress;
