import { ArrowBack } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { parse, format } from 'date-fns';
import PropTypes from 'prop-types';

const CLAIM_STATUS_MESSAGES_BY_LOCALE = {
  A: {
    en: 'You did not meet the reporting or filing requirements of the law. You received a notice with the details.',
    es: 'No cumplió con los requisitos de reportando o de presentación de informes que exige la ley. Recibió una notificación con los detalles.',
  },
  B: {
    en: 'You are not eligible according to the law. You received a notice with the details.',
    es: 'No es elegible de acuerdo con la ley. Recibió un aviso con los detalles.',
  },
  C: {
    en: 'You are under a disqualification. You received a notice with the details. If you have filed an appeal, continue to file your weekly claim.',
    es: 'Está usted ahora bajo una descalificación. Recibió una notificación con los detalles. Si presentó una apelación, continúe con la presentación de su reclamo semanal.',
  },
  D: {
    en: 'An unemployment claim was filed using this name using this address. If you did not file this claim, please call 1-844-908-2178 to report this. If you did file this claim, please report to the closest Arkansas Workforce Center with a copy of this notice and your government-issued ID.',
    es: 'Se presentó una solicitud de desempleo con este nombre y esta dirección. Si no presentó esta solicitud, llame al 1-844-908-2178 para informarlo. Si presentó esta solicitud, preséntese en el Centro de Trabajo de Arkansas más cercano con una copia de este aviso y su identificación emitida por el gobierno.',
  },
  E: {
    en: 'The wages in the base period of your claim is not enough to set up a claim. Any missing wages that are added may set up a claim and a notice will be mailed to you.  Continue to file your weekly claim.',
    es: 'Los salarios del período base de su reclamo no son suficientes para presentar un reclamo. Los salarios que falten se pueden añadir a la solicitud y se le enviará una notificación por correo. Continúe presentando su reclamo semanal.',
  },
  F: {
    en: 'A decision is pending on your claim. Once a decision is made, a notice will be mailed to you.  Continue to file your weekly claim.',
    es: 'Su reclamo está pendiente de una decisión. Una vez que se tome una decisión, se le enviará una notificación por correo. Continúe presentando su reclamo semanal.',
  },
  G: {
    en: 'The week you claimed is before the effective filing date of your initial claim.',
    es: 'La semana que usted reclamó es anterior a la fecha de presentación efectiva de su reclamo inicial.',
  },
  H: {
    en: 'You have been paid 25 weeks of Shared Work benefits. This is the maximum number of weeks payable in this program.  You may have benefits left on your regular UI claim.  Call 1-844-908-2178 to check on this.',
    es: 'Le han pagado 25 semanas de beneficios de Trabajo Compartido. Esta es la cantidad máxima de semanas que se le pueden pagar en este programa. Es posible que aún le queden beneficios en su solicitud de seguro de desempleo regular. Llame al 1-844-908-2178 para consultarlo.',
  },
  I: {
    en: 'You are not considered unemployed because you are working 40 hours or more or you are on leave through the Family Medical Leave Act.',
    es: 'No se le considera desempleado porque trabaja 40 horas o más o está de licencia según la Ley de Licencia Médica Familiar.',
  },
  J: {
    en: 'You will not be paid benefits because money being taken out equals or is more than what you qualified for weekly. Call 1-844-908-2178 for more information.',
    es: 'No recibirá los beneficios porque el dinero que le descuentan es igual o mayor de lo que le corresponde por semana. Llame al 1-844-908-2178 para obtener más información.',
  },
  K: {
    en: 'You are under a disqualification due to fraud.  This week counts towards that penalty. Continue to file your weekly claim.',
    es: 'Está usted ahora bajo una descalificación por fraude. Esta semana cuenta para esa sanción. Continúe presentando su reclamo semanal.',
  },
  L: {
    en: 'You will not be paid benefits because your earnings, retirement pay, and separation pay is more than what you qualified for weekly.  Call 1-844-908-2178 for more information.',
    es: 'No recibirá beneficios porque sus ingresos, paga de jubilación o  pago por separación del trabajo son más de lo que le corresponde semanalmente. Llame al 1-844-908-2178 para obtener más información.',
  },
  M: {
    en: 'A 10 Day Notice was mailed to you asking for additional information. Please contact the UI Hotline at 1-844-908-2178 to provide this information.  If your government-issued photo ID was requested in the notice and you filed your claim on EZARC, use the link on your confirmation page to  verify your ID through Login.gov or the United States Postal Service.  You can also go to your nearest Arkansas Workforce Center to have your photo ID scanned.',
    es: 'Se le envió por correo un aviso de 10 días en el que se le solicitaba información adicional. Comuníquese con la línea directa de UI al 1-844-908-2178 para proporcionar esta información. Si se le solicitó su identificación con fotografía emitida por el gobierno en el aviso y presentó su reclamo en EZARC, use el enlace en su página de confirmación para verificar su identificación a través de Login.gov o el Servicio Postal de los Estados Unidos. También puede ir al Centro de Fuerza Laboral de Arkansas más cercano para que escaneen su identificación con fotografía.',
  },
  N: {
    en: 'You have previously claimed this week.',
    es: 'Usted ya ha reclamado esta semana anteriormente.',
  },
  O: {
    en: 'You have an overpayment due to fraud that has to be paid back before you can receive benefits.  This includes penalty and interest.',
    es: 'Tiene un sobrepago debido por fraude que debe devolver antes de poder recibir los beneficios. Esto incluye multas e intereses.',
  },
  P: {
    en: 'The benefit year of your current claim has expired.  Go to EZARC at www.ezarc.adws.arkansas.gov to file a new claim.',
    es: 'El año de beneficios de su reclamo actual ha expirado. Visite EZARC en www.ezarc.adws.arkansas.gov para presentar un nuevo reclamo.',
  },
  Q: {
    en: 'Your claim is inactive due to a skip of one or more weeks since you last claimed. You will need to go to EZARC at www.ezarc.adws.arkansas.gov to reopen your claim.',
    es: 'Su solicitud está inactiva porque han pasado una o más semanas  desde la última vez que presentó su reclamacion semanal. Deberá dirigirse a EZARC en www.ezarc.adws.arkansas.gov para reabrir su reclamo.',
  },
  R: {
    en: 'You are under a disqualification. You received a notice with the details. If you have filed an appeal, continue to file your weekly claim.',
    es: 'Está usted ahora bajo una descalificación. Recibió una notificación con los detalles. Si presentó una apelación, continúe con la presentación de su reclamo semanal.',
  },
  S: {
    en: 'This week is being used as your valid waiting week.',
    es: 'Esta semana se utiliza como su semana de espera válida.',
  },
  T: {
    en: 'Your earnings were too much for you to receive benefits.',
    es: 'Sus ganancias fueron demasiado altas para que usted pudiera recibir beneficios.',
  },
  U: {
    en: 'You failed to  qualify for benefits.  Call 1-844-908-2178 for more information.',
    es: 'Ha falldo para calificar para los beneficios. Llame al 1-844-908-2178 para obtener más información.',
  },
  V: {
    en: 'Benefits for this week are being used to pay on an overpayment balance.  Call 1-844-908-2178 for more information.',
    es: 'Los beneficios de esta semana se están utilizando para pagar el saldo de un soberpago. Llame al 1-844-908-2178 para obtener más información.',
  },
  W: {
    en: 'Your claim was based on seasonal wages and this week is being claimed outside of the seasonal period for your wages.  Call 1-844-908-2178 for more information.',
    es: 'Su reclamación  se ha basado en salarios de temporada y esta semana se reclama fuera del período estacional de sus salarios. Llame al 1-844-908-2178 para obtener más información.',
  },
  X: {
    en: 'Vacant',
    es: 'Vacante',
  },
  Y: {
    en: 'You are under a disqualification. You received a notice with the details. If you have filed an appeal, continue to file your weekly claim.',
    es: 'Está usted ahora bajo una descalificación. Recibió una notificación con los detalles. Si presentó una apelación, continúe con la presentación de su reclamo semanal.',
  },
  Z: {
    en: 'You have been paid all of your benefits for this claim.',
    es: 'Se le han pagado todos los beneficios por este reclamo.',
  },
};

function ContinuedClaimConfirmation() {
  const intl = useIntl();
  const { selectedClaim } = useSelector((state) => state.ui);
  const parsedWeekStart = selectedClaim.weekStart
    ? format(parse(selectedClaim.weekStart, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')
    : '';
  const parsedWeekEnd = selectedClaim.weekEnd
    ? format(parse(selectedClaim.weekEnd, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')
    : '';
  const claimStatusErrorMessage = CLAIM_STATUS_MESSAGES_BY_LOCALE?.[selectedClaim.claimStatus]?.[intl.locale];

  if (claimStatusErrorMessage) {
    return (
      <Box sx={{ py: 3.75 }}>
        <Alert severity="error">
          <AlertTitle color="inherit" sx={{ fontWeight: 'bold' }}>
            {intl.formatMessage({ id: 'uiDashboard.attention' })}
          </AlertTitle>
          <Typography color="inherit">{claimStatusErrorMessage}</Typography>
          <Button
            component={Link}
            to="/ui-dashboard"
            sx={{ mt: 2 }}
            variant="contained"
            color="secondary"
            startIcon={<ArrowBack />}
          >
            {intl.formatMessage({ id: 'continuedClaim.confirmation.successAlert.cta' })}
          </Button>
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ py: 3.75 }}>
      <Alert severity="success">
        <AlertTitle color="inherit" sx={{ fontWeight: 'bold' }}>
          {intl.formatMessage(
            { id: 'continuedClaim.confirmation.successAlert.title' },
            { weekStart: parsedWeekStart, weekEnd: parsedWeekEnd }
          )}
        </AlertTitle>
        <Typography color="inherit">
          {intl.formatMessage({ id: 'continuedClaim.confirmation.successAlert.body' })}
        </Typography>
        <Button
          component={Link}
          to="/ui-dashboard"
          sx={{ mt: 2 }}
          variant="contained"
          color="secondary"
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage({ id: 'continuedClaim.confirmation.successAlert.cta' })}
        </Button>
      </Alert>
    </Box>
  );
}

ContinuedClaimConfirmation.propTypes = {
  claimStatus: PropTypes.string,
};

export default ContinuedClaimConfirmation;
