import { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import PropTypes from 'prop-types';

/* API */
import { matchClaimantAccount } from 'lib/API';

/* Material UI and other UI Dependencies */
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  useMediaQuery,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
  Alert,
} from '@mui/material';
import { darken } from '@mui/material/styles';
import SSNInput from 'components/inputs/FullSSNInput';
import DateInput from 'components/forms/DateInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { WhiteFilledInput, convertDate } from 'components/forms/OnboardingContact';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Utils */
import { preventSubmitOnEnter } from 'lib/utils';

/* Redux */
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function SSNDialog({ open, setOpen }) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const isSmall = useMediaQuery('(max-width:767px)');
  const [ssnType, setSsnType] = useState('text');
  const history = useHistory();

  const validationSchema = yup.object({
    dob: yup
      .date('forms.onboarding.dateOfBirthValidation')
      .typeError('forms.onboarding.dateOfBirthInvalid')
      .min('01/01/1900', 'forms.onboarding.dateOfBirthTooOld')
      .max(new Date(), 'forms.onboarding.dateOfBirthTooNew')
      .required('forms.onboarding.dateOfBirthRequired'),
    ssn: yup
      .string('forms.onboarding.fullSocialSecurityNumber')
      .matches(/^[0-9]+$/, 'forms.onboarding.onlyDigits')
      .min(9, 'forms.onboarding.exactlyXDigits')
      .max(9, 'forms.onboarding.exactlyXDigits')
      .required('forms.onboarding.ssnRequired'),
  });

  const matchClaimantAccountMutation = useMutation({ mutationFn: (values) => matchClaimantAccount(values) });

  const initialValues = {
    ssn: '',
    dob: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      matchClaimantAccountMutation.mutate(
        { ssn: values.ssn, dob: convertDate(values.dob) },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['getClaimantInfo']);
            queryClient.invalidateQueries(['/users/me/worksearch/']);
            setOpen(false);
          },
        }
      );
    },
  });

  return (
    <Dialog maxWidth="sm" open={open} fullWidth onClose={() => {}}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box mt={1} mb={3}>
            <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
              {intl.formatMessage({ id: 'jobSearchActivity.title' })}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" component="p" sx={{ fontSize: 14, padding: '0 16px 0' }}>
              {intl.formatMessage({ id: 'forms.onboarding.uiClaimant.body1' })}
            </Typography>
            <Box component="ul" sx={{ marginY: 1 }}>
              <Typography component="li" variant="body2" sx={{ fontSize: 14 }}>
                {intl.formatMessage({ id: 'forms.onboarding.uiClaimant.bullet1' })}
              </Typography>
              <Typography component="li" variant="body2" sx={{ fontSize: 14 }}>
                {intl.formatMessage({ id: 'forms.onboarding.uiClaimant.bullet2' })}
              </Typography>
              <Typography component="li" variant="body2" sx={{ fontSize: 14 }}>
                {intl.formatMessage({ id: 'forms.onboarding.uiClaimant.bullet3' })}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ fontSize: 14, padding: '0 16px 0' }}>
              {intl.formatMessage({ id: 'forms.onboarding.uiClaimant.body2' })}
            </Typography>
            <Box m={1} />
          </Box>
          <Box pt={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
            <div style={{ position: 'relative' }}>
              <FormControl error={formik.touched.ssn && Boolean(formik.errors.ssn)}>
                <InputLabel htmlFor="social-security-onboard-link" variant="filled">
                  {intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber' })}
                </InputLabel>
                <SSNInput
                  name="ssn"
                  type={ssnType}
                  customInput={WhiteFilledInput}
                  fullWidth
                  autoComplete="off"
                  label={intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber' })}
                  value={formik.values.ssn}
                  onChange={formik.handleChange}
                  onKeyDown={preventSubmitOnEnter}
                  inputProps={{
                    title: intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber' }),
                  }}
                  id="social-security-onboard-link"
                />
                {formik.touched.ssn && formik.errors.ssn && (
                  <FormHelperText>{intl.formatMessage({ id: formik.errors.ssn }, { amount: 9 })}</FormHelperText>
                )}
              </FormControl>

              <IconButton
                title={intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber' })}
                style={{ position: 'absolute', right: '0', top: '0' }}
                onClick={() => {
                  setSsnType(ssnType === 'password' ? 'text' : 'password');
                }}
                size="large"
              >
                {ssnType === 'text' ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </div>
            <FormControl error={formik.touched.dob && Boolean(formik.errors.dob)}>
              <InputLabel htmlFor="dob-onboard-link" variant="filled">
                {intl.formatMessage({ id: 'forms.onboarding.dateOfBirth' })}
              </InputLabel>
              <DateInput
                name="dob"
                customInput={WhiteFilledInput}
                fullWidth
                autoComplete="off"
                value={formik.values.dob}
                onChange={formik.handleChange}
                onKeyDown={preventSubmitOnEnter}
                onBlur={formik.handleBlur}
                id="dob-onboard-link"
              />
              {formik.touched.dob && formik.errors.dob && (
                <FormHelperText>{intl.formatMessage({ id: formik.errors.dob }, { amount: 9 })}</FormHelperText>
              )}
            </FormControl>
          </Box>
          {Boolean(matchClaimantAccountMutation.isError) && (
            <Alert sx={{ mt: 1.5 }} severity="error">
              <FormattedMessage
                id="forms.onboarding.uiClaimant.defaultAlertError"
                values={{
                  p: (chunks) => <Typography>{chunks}</Typography>,
                  link: (chunks) => (
                    <Typography
                      component="a"
                      href={`mailto:${chunks}`}
                      sx={{
                        color: (theme) => darken(theme.palette.info.main, 0.1),
                        textDecoration: 'underline',
                        fontWeight: 700,
                        '&:hover': {
                          color: (theme) => darken(theme.palette.info.main, 0.2),
                        },
                      }}
                    >
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Box mb={2}>
            <Button
              disabled={matchClaimantAccountMutation.isLoading}
              onClick={() => history.goBack()}
              color="secondary"
              variant="outlined"
            >
              {intl.formatMessage({ id: 'jobSearchActivity.goBack' })}
            </Button>
          </Box>
          <Box mb={2} pr={2}>
            <Button
              color="secondary"
              disabled={matchClaimantAccountMutation.isLoading}
              size="large"
              type="submit"
              variant="contained"
            >
              {intl.formatMessage({ id: 'experience.form.save' })}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}

SSNDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refreshCounter: PropTypes.number,
  setRefreshCounter: PropTypes.func,
};
