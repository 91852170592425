import { isValid, parseISO, differenceInHours } from 'date-fns';

export const isLessThan24HoursAgo = (lastUpdated: string) => {
  if (!lastUpdated) return false; // Treat empty string as not outdated

  const date = parseISO(lastUpdated);
  if (!isValid(date)) return false; // Ensure it's a valid date

  return differenceInHours(new Date(), date) < 24;
};
