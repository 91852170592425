import { useEffect, useRef, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useAsync, useWindowSize } from 'react-use';
import { LoadingSmall } from 'components/Loading';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

// Ensure that the pdf.worker.mjs file exists in the public directory.
// If it is missing, run the `copy-pdf-worker.js` script to copy it there.
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.mjs`;

function PDFViewer({
  value,
  file,
  handleChangePdfUrl,
}: {
  value?: React.JSX.Element;
  file?: string;
  handleChangePdfUrl: (value: string) => void;
}) {
  const [pageWidth, setPageWidth] = useState(0);
  const [previousRenderValue, setPreviousRenderValue] = useState<string | null>(null);
  const documentWrapperRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowSize();

  const render = useAsync(async () => {
    if (file) return file;
    if (!value) return null;

    const blob = await pdf(value).toBlob();
    const url = URL.createObjectURL(blob);

    return url;
  }, [value]);

  useEffect(() => handleChangePdfUrl(render.value as string), [render.value]);

  useEffect(() => {
    // Update the page width based on the current container width
    if (documentWrapperRef.current) {
      setPageWidth(documentWrapperRef.current.offsetWidth);
    }
  }, [windowWidth]); // Re-run when window width changes

  const isFirstRendering = !previousRenderValue;

  const isLatestValueRendered = previousRenderValue === render.value;
  const isBusy = render.loading || !isLatestValueRendered;

  const shouldShowPreviousDocument = !isFirstRendering && isBusy;
  const shouldShowLoadingSpinner = !render.value || (isFirstRendering && render.loading);

  return (
    <Box
      sx={{
        boxShadow: (theme) => (shouldShowLoadingSpinner ? 'none' : theme.shadows[3]),
        '& .react-pdf__Document': {
          '&.previous-document': {
            '& canvas': {
              opacity: 0.5,
            },
          },
          '&.rendering-document': {
            position: 'absolute',
            boxShadow: 'none',
          },
        },
      }}
      ref={documentWrapperRef}
    >
      {shouldShowLoadingSpinner ? (
        <LoadingSmall />
      ) : (
        <Box>
          {shouldShowPreviousDocument && previousRenderValue && !isLatestValueRendered ? (
            <Document key={previousRenderValue} className="previous-document" file={previousRenderValue} loading={null}>
              <Page pageNumber={1} loading={null} width={pageWidth} />
            </Document>
          ) : null}
          <Document
            key={render.value}
            className={shouldShowPreviousDocument ? 'rendering-document' : null}
            file={render.value}
            loading={null}
          >
            <Page
              pageNumber={1}
              width={pageWidth}
              onRenderSuccess={() => setPreviousRenderValue(render.value as string)}
              loading={null}
            />
          </Document>
        </Box>
      )}
    </Box>
  );
}

PDFViewer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  handleChangePdfUrl: PropTypes.func.isRequired,
};

export default PDFViewer;
