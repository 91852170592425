import { z } from 'zod';

export const MatchClaimantAccountRequestSchema = z.object({
  ssn: z.string(),
  dob: z.string(),
});

export const ValidateAndInitiateClaimResponseSchema = z.object({
  Data: z.object({
    ClaimCallNum: z.number(),
    FirstQuestionID: z.string(),
    WeekStartDate: z.string(),
    WeekEndDate: z.string(),
    Message: z.string(),
  }),
});

export const SaveContinuousClaimRecordRequestSchema = z.object({
  question_id: z.string(),
  claim_call_number: z.number(),
  question_type: z.string(),
  bwe: z.string(),
  variable_input: z.string().optional(),
  date_input: z.coerce.string().optional(),
});

export const SaveContinuousClaimRecordResponseSchema = z.object({
  next_question_id: z.string(),
});

export const GetClaimantInfoResponseSchema = z.object({
  Data: z.object({
    ProcessLastClaim: z.string(),
    MaxBenefitAmt: z.string(),
    PaidToDateAmt: z.string(),
    ClaimEffDate: z.string().nullable(),
    WBALastClaim: z.string(),
    NetLastClaim: z.string(),
    WaitingPeriodFlag: z.string(),
    WeekEndingLastClaim: z.string(),
    BYQ: z.number(),
    ClaimStatus: z.string().optional(),
  }),
});

export const GetMailingAddressResponseSchema = z.object({
  address_1: z.string(),
  address_2: z.string().optional(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
});

export const VerifyRoutingNumberRequestSchema = z.object({
  account_type: z.string(),
  routing_number: z.string(),
});

export const GetPaymentInfoRequestSchema = z.object({
  byq: z.number(),
});

export const GetBankingInfoResponseSchema = z.object({
  byq: z.number(),
  confirmation_number: z.string(),
  message: z.string().nullable(),
  payment_type: z.string(),
  last_updated: z.string().datetime().or(z.literal('')),
});

export const VerifyRoutingNumberResponseSchema = z.object({
  account_type: z.string(),
  routing_number: z.string(),
  bank_name: z.string().nullable(),
});

export const SubmitDirectDepositSchema = z.object({
  account_type: z.string(),
  routing_number: z.string(),
  account_number: z.string(),
});
