import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Box,
  Container,
  Typography,
  Alert,
  AlertTitle,
  darken,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const UILaws = () => {
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const [contentsAccepted, setContentsAccepted] = useState(false);
  let history = useHistory();

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'uiLaws.windowTitle' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('UI_DASHBOARD');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 6, mb: 2.5 }}>
        <Typography variant="h1">{intl.formatMessage({ id: 'uiLaws.title' })}</Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Alert severity="warning" sx={{ mt: 3 }}>
        <AlertTitle color="inherit" sx={{ fontWeight: 'bold' }}>
          {intl.formatMessage({ id: 'uiLaws.warningTitle' })}
        </AlertTitle>
        <FormattedMessage
          id="uiLaws.warningText"
          values={{
            icon: (
              <OpenInNewIcon
                sx={{
                  fontSize: 16,
                  position: 'relative',
                  top: 4,
                  pl: 0.5,
                  color: (theme) => darken(theme.palette.info.main, 0.1),
                }}
              />
            ),
            p: (chunks) => <Typography>{chunks}</Typography>,
            link: (chunks) => (
              <Typography
                component="a"
                href="https://dws.arkansas.gov/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: (theme) => darken(theme.palette.info.main, 0.1),
                  textDecoration: 'underline',
                  fontWeight: 700,
                  '&:hover': {
                    color: (theme) => darken(theme.palette.info.main, 0.2),
                  },
                }}
              >
                {chunks}
              </Typography>
            ),
          }}
        />
      </Alert>

      <Box
        sx={{
          mt: 3,
          mb: 2.5,
        }}
      >
        <FormattedMessage
          id="uiLaws.legalText"
          values={{
            header: (chunks) => (
              <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
                {chunks}
              </Typography>
            ),
            p: (chunks) => (
              <Typography variant="body2" component="p" sx={{ mb: 1.5 }}>
                {chunks}
              </Typography>
            ),
            ul: (chunks) => (
              <Typography component="ul" sx={{ mt: 1, mb: 2.5, pl: 4 }}>
                {chunks}
              </Typography>
            ),
            li: (chunks) => <Typography component="li">{chunks}</Typography>,
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={contentsAccepted}
              onChange={(e) => {
                setContentsAccepted(e.currentTarget.checked);
              }}
            />
          }
          label={intl.formatMessage({ id: 'uiLaws.confirm' })}
          sx={{ mt: 1 }}
        />
      </Box>
      <Box sx={{ mt: 2, mb: 4 }}>
        <Button
          type="button"
          onClick={() => history.push(`${urlPrefix}/ui-dashboard`)}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          {intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}
        </Button>
        <Button
          type="button"
          onClick={() => {
            history.push(`${urlPrefix}/ui-work-search-activity`);
          }}
          variant="contained"
          disabled={!contentsAccepted}
        >
          {intl.formatMessage({ id: 'fileClaim.buttons.proceed' })}
        </Button>
      </Box>
    </Container>
  );
};

export default UILaws;
