import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { format, addDays, differenceInDays, startOfDay } from 'date-fns';
import { or } from 'ramda';
import PropTypes from 'prop-types';

/* i18n */
import { FormattedMessage, useIntl, FormattedNumber } from 'react-intl';

/* API */
import { getClaimantInfo } from 'lib/API/ar-ui';
import { validateAndInitiateClaim } from 'lib/API';
import { getUtcDate } from 'lib/utils';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedClaim } from 'app/slices/uiSlice';

import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Button,
  Card,
  CardContent,
  Stack,
  lighten,
  Alert,
  AlertTitle,
  Divider,
  darken,
} from '@mui/material';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';

const UIDashboard = () => {
  const [initiatingClaim, setInitiatingClaim] = useState(false);
  const [consultingClaimWeek, setConsultingClaimWeek] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const { selectedClaim } = useSelector((state) => state.ui);
  const [localWeekEnd, setLocalWeekEnd] = useState(null);

  const dispatch = useDispatch();
  let history = useHistory();

  const queryParams = new URLSearchParams(useLocation().search);
  const errorQueryParam = queryParams.get('error');

  const claimantInfo = useQuery({ queryFn: () => getClaimantInfo({}), queryKey: ['getClaimantInfo'], retry: false });

  useEffect(() => {
    if (!selectedClaim.weekEnd || selectedClaim.weekEnd === '-') {
      setConsultingClaimWeek(true);
      validateAndInitiateClaim()
        .then((response) => {
          setLocalWeekEnd(response.Data.WeekEndDate.slice(0, 10));
        })
        .catch(() => {
          setConsultingClaimWeek(false);
        })
        .finally(() => {
          setConsultingClaimWeek(false);
        });
    }
  }, []);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'uiDashboard.yourActivity' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('UI_DASHBOARD');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  const handleFileClaim = () => {
    setInitiatingClaim(true);
    validateAndInitiateClaim()
      .then((response) => {
        if (response.Data.ClaimCallNum < 0) {
          const errorCode = response?.Data?.Message ?? 'unknown';
          setErrorCode(errorCode ? errorCode : 'unknown');
          history.push(`${urlPrefix}/ui-dashboard?error=true`);
          return;
        }
        dispatch(
          setSelectedClaim({
            weekStart: response.Data.WeekStartDate.slice(0, 10),
            weekEnd: response.Data.WeekEndDate.slice(0, 10),
            initiatedClaim: response.Data,
            claimStatus: claimantInfo?.data?.Data?.ClaimStatus ?? '',
          })
        );
        history.push(`${urlPrefix}/ui-law-changes`);
      })
      .catch((error) => {
        const errorCode = error?.response?.data?.detail?.arknet_code;
        setErrorCode(errorCode === 'E4230' ? errorCode : 'unknown');
        history.push(`${urlPrefix}/ui-dashboard?error=true`);
      })
      .finally(() => {
        setInitiatingClaim(false);
      });
  };

  if (claimantInfo.isError) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 3 }}>
          <AlertTitle color="inherit" sx={{ fontWeight: 'bold' }}>
            {intl.formatMessage({ id: 'uiDashboard.attention' })}
          </AlertTitle>
          {intl.formatMessage({ id: 'uiDashboard.error' })}
        </Alert>
      </Container>
    );
  }

  if (claimantInfo.isLoading || consultingClaimWeek) {
    return (
      <Box mt={6} mb={2.5} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (errorCode && Boolean(errorQueryParam)) {
    const errorTitleMap = {
      I0168: 'app.error',
      E4230: 'uiDashboard.systemNotAvailableTitle',
    };
    return (
      <Container maxWidth="lg">
        <DashboardHeader />
        <Divider sx={{ mt: 2, mb: 3 }} />
        <Box>
          <Alert severity="error" sx={{ mt: 3 }}>
            <AlertTitle color="inherit" sx={{ fontWeight: 'bold' }}>
              {intl.formatMessage({ id: errorCode in errorTitleMap ? errorTitleMap[errorCode] : 'app.error' })}
            </AlertTitle>
            {<ErrorMessage errorCode={errorCode} />}
          </Alert>
          <Button variant="contained" sx={{ mt: 3 }} LinkComponent={Link} to={`${urlPrefix}/ui-dashboard`}>
            {intl.formatMessage({ id: 'uiDashboard.backToDashboard' })}
          </Button>
        </Box>
      </Container>
    );
  }

  const weekEndingLastClaim = getUtcDate(claimantInfo?.data?.Data?.WeekEndingLastClaim);
  const unixTime = new Date(0);
  const currentClaimWeekEnd =
    selectedClaim.weekEnd && getUtcDate(selectedClaim.weekEnd) > unixTime
      ? getUtcDate(selectedClaim.weekEnd)
      : getUtcDate(localWeekEnd);
  const daysLeftToCertify = differenceInDays(addDays(currentClaimWeekEnd, 13), startOfDay(new Date()));
  const effectiveDate =
    claimantInfo?.data?.Data?.ClaimEffDate &&
    getUtcDate(claimantInfo?.data?.Data?.ClaimEffDate?.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));

  return (
    <Container maxWidth="lg">
      <DashboardHeader />
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Box>
        <Box
          component="section"
          m="1"
          sx={{ p: 2, borderTopLeftRadius: 4, borderTopRightRadius: 4, bgcolor: 'secondary.main' }}
        >
          <Stack direction="row">
            <CalendarMonthOutlinedIcon sx={{ fontSize: 22, color: 'white' }} />
            <Typography component="h2" sx={{ fontWeight: 700, pl: 1, color: 'white' }}>
              {intl.formatMessage({ id: 'uiDashboard.unemploymentClaimInfo' })}
            </Typography>
          </Stack>
        </Box>
        <Box
          borderRadius={'0px 0px 4px 4px'}
          sx={{ p: 2.5, bgcolor: (theme) => lighten(theme.palette.secondary.main, 0.93) }}
        >
          <Card
            sx={{
              mt: 1,
              borderRadius: 0.5,
              borderColor: grey['200'],
              borderStyle: 'solid',
              borderWidth: 1,
            }}
            elevation={0}
          >
            <CardContent>
              <Box p={1}>
                <Typography variant="h4" component="h3" sx={{ mb: 1.25 }}>
                  {intl.formatMessage({ id: 'uiDashboard.benefitsSummary' })}
                </Typography>
                <Box sx={{ mb: 0.5 }}>
                  <Typography variant="body1" component="span">
                    {intl.formatMessage({ id: 'uiDashboard.claimFiledOn' })}
                  </Typography>
                  {effectiveDate ? (
                    <Typography variant="title" sx={{ fontWeight: 500, pl: 1 }} component="span">
                      {format(effectiveDate, 'MM/dd/yyyy')}
                    </Typography>
                  ) : null}
                </Box>
                <Box sx={{ mb: 0.5 }}>
                  <Typography variant="body1" component="span">
                    {intl.formatMessage({ id: 'uiDashboard.remainingBalanceOnRecord' })}
                  </Typography>
                  <Typography variant="title" sx={{ fontWeight: 500, pl: 1 }} component="span">
                    <FormattedNumber
                      value={
                        Number(claimantInfo?.data?.Data?.MaxBenefitAmt) -
                        or(0, Number(claimantInfo?.data?.Data?.PaidToDateAmt))
                      }
                      style="currency"
                      currency="USD"
                    />
                  </Typography>
                </Box>
                <Box sx={{ mb: 0.5 }}>
                  <Typography variant="body1" component="span">
                    {intl.formatMessage({ id: 'uiDashboard.benefitsAmount' })}
                  </Typography>
                  <Typography variant="title" sx={{ fontWeight: 500, pl: 1 }} component="span">
                    <FormattedNumber
                      value={Number(claimantInfo?.data?.Data?.WBALastClaim)}
                      style="currency"
                      currency="USD"
                    />
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" component="span">
                    {intl.formatMessage({ id: 'uiDashboard.afterDeductions' })}
                  </Typography>
                  <Typography variant="title" sx={{ fontWeight: 500, pl: 1 }} component="span">
                    <FormattedNumber
                      value={Number(claimantInfo?.data?.Data?.NetLastClaim)}
                      style="currency"
                      currency="USD"
                    />
                  </Typography>
                </Box>
                <Typography variant="h4" component="h3" sx={{ mt: 3, mb: 1.25 }}>
                  {intl.formatMessage({ id: 'uiDashboard.lastWeekClaimed' })}
                </Typography>
                <Box>
                  <Typography variant="body1" component="span">
                    {intl.formatMessage({
                      id:
                        weekEndingLastClaim && weekEndingLastClaim > unixTime
                          ? 'uiDashboard.lastWeekFiledDesc'
                          : 'uiDashboard.noWeeksFiled',
                    })}
                  </Typography>
                  <Typography variant="title" sx={{ fontWeight: 500, pl: 1 }} component="span">
                    {weekEndingLastClaim && weekEndingLastClaim > unixTime
                      ? format(weekEndingLastClaim, 'MM/dd/yyyy')
                      : ''}
                  </Typography>
                </Box>
                <Typography variant="h4" component="h3" sx={{ mt: 3, mb: 1.25 }}>
                  {intl.formatMessage({ id: 'uiDashboard.waitingWeek' })}
                </Typography>
                <Box>
                  <Typography variant="body1" component="span">
                    {intl.formatMessage({
                      id:
                        claimantInfo?.data?.Data?.WaitingPeriodFlag === 'Y'
                          ? 'uiDashboard.alreadyServed'
                          : 'uiDashboard.haveNotServed',
                    })}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box
        sx={{
          my: 3.5,
        }}
      >
        <Box component="section" m="1" sx={{ p: 2, borderRadius: '4px 4px 0px 0px', bgcolor: 'secondary.main' }}>
          <Stack direction="row">
            <FormatListBulletedOutlinedIcon sx={{ fontSize: 22, color: 'white' }} />
            <Typography component="h2" sx={{ fontWeight: 700, pl: 1, color: 'white' }}>
              {intl.formatMessage({ id: 'uiDashboard.yourActivity' })}
            </Typography>
          </Stack>
        </Box>
        <Box
          borderRadius="0px 0px 4px 4px"
          sx={{ padding: '16px', bgcolor: (theme) => lighten(theme.palette.secondary.main, 0.93) }}
        >
          <div>
            <Card
              sx={{
                mt: 1,
                borderRadius: 0.5,
                borderColor: grey['200'],
                borderStyle: 'solid',
                borderWidth: 1,
              }}
              elevation={0}
            >
              <CardContent>
                <Typography component={'span'} variant="h6" align="center">
                  {daysLeftToCertify > 0 ? daysLeftToCertify : ''}
                </Typography>
                <Typography component={'span'} variant="body1">
                  {daysLeftToCertify > 0 ? ' ' : ''}
                  {intl.formatMessage({
                    id:
                      daysLeftToCertify >= 2
                        ? 'uiDashboard.daysLeftToCertify'
                        : daysLeftToCertify > 0
                        ? 'uiDashboard.dayLeftToCertify'
                        : 'uiDashboard.lastDayToCertify',
                  })}{' '}
                </Typography>
                <Typography component={'span'} variant="h6" align="center">
                  {format(currentClaimWeekEnd, 'MM/dd/yyyy')}
                </Typography>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: { xs: 'center', lg: 'flex-end' } }}>
                  <Button
                    disabled={initiatingClaim}
                    variant="contained"
                    onClick={() => handleFileClaim()}
                    id="file-a-claim-UI-claim-button"
                  >
                    {intl.formatMessage({ id: 'uiDashboard.fileContinuedClaim' })}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </div>
        </Box>
      </Box>
    </Container>
  );
};

function DashboardHeader() {
  const intl = useIntl();
  return (
    <Box
      sx={{
        mt: 6,
        mb: 2.5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: { md: 'flex-end' },
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Typography variant="h1">
        <CalendarMonthOutlinedIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
        {intl.formatMessage({ id: 'layout.menu.uidashboard' })}
      </Typography>
    </Box>
  );
}

function ErrorMessage({ errorCode }) {
  const errorMessageMap = {
    I0167: 'uiDashboard.alreadyFiled',
    I0168: 'uiDashboard.inactiveClaim',
    I0170: 'uiDashboard.notAuthorized',
    E4230: 'uiDashboard.systemNotAvailable',
  };
  return errorCode ? (
    <FormattedMessage
      id={errorCode in errorMessageMap ? errorMessageMap[errorCode] : 'alerts.unexpectedError'}
      values={{
        icon: (
          <OpenInNewIcon
            sx={{
              fontSize: 16,
              position: 'relative',
              top: 4,
              pl: 0.5,
              color: (theme) => darken(theme.palette.error.dark, 0.4),
            }}
          />
        ),
        h5: (chunks) => (
          <Typography sx={{ color: 'inherit', fontWeight: 500 }} component="h3" gutterBottom>
            {chunks}
          </Typography>
        ),
        p: (chunks) => (
          <Typography sx={{ color: 'inherit' }} gutterBottom>
            {chunks}
          </Typography>
        ),
        link: (chunks) => (
          <Typography
            component="a"
            href={`https://${chunks}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: 'inherit',
              textDecoration: 'underline',
              fontWeight: 700,
              '&:hover': {
                color: (theme) => darken(theme.palette.error.dark, 0.5),
              },
            }}
          >
            {chunks}
          </Typography>
        ),
        tel: (chunks) => (
          <Typography
            component="a"
            href={`tel:${chunks}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: 'inherit',
              textDecoration: 'underline',
              fontWeight: 700,
              '&:hover': {
                color: (theme) => darken(theme.palette.error.dark, 0.5),
              },
            }}
          >
            {chunks}
          </Typography>
        ),
      }}
    />
  ) : null;
}
ErrorMessage.propTypes = {
  errorCode: PropTypes.string,
};

export default UIDashboard;
