const CONTINUED_CLAIM_QUESTIONS = {
  1005001: {
    // yesNoRadioGroup| numericInt | numericFloat | date | select
    id: '1005001',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'During the week did you work for an employer?',
      es: '¿Durante la semana trabajaste para una empleadora?',
    },
  },
  1005011: {
    id: '1005011',
    inputType: 'date',
    label: {
      en: 'Enter your last day worked during the week.',
      es: 'Ingrese su último día trabajado durante la semana.',
    },
  },
  1005007: {
    id: '1005007',
    inputType: 'select',
    label: {
      en: 'Select one option from the list which describes your current work status:',
      es: 'Seleccione una opción de la lista que describa su estado laboral actual:',
    },
    options: [
      {
        value: 'STILL_EMPL',
        label: {
          en: 'Still Employed or Working Reduced Hours',
          es: 'Todavía empleado o trabajando con horas reducidas',
        },
      },
      {
        value: 'LACK_WORK',
        label: {
          en: 'Laid Off',
          es: 'Cesado',
        },
      },
      {
        value: 'FIRED',
        label: {
          en: 'Fired',
          es: 'Despedido',
        },
      },
      {
        value: 'QUIT',
        label: {
          en: 'Quit',
          es: 'Renuncio',
        },
      },
      {
        value: 'OTHER',
        label: {
          en: 'Other',
          es: 'Otro',
        },
      },
    ],
  },
  1005027: {
    id: '1005027',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Is the employer the same employer as the one that was filled on the initial claim?',
      es: '¿El empleador es el mismo empleador que se llenó en el reclamo inicial?',
    },
  },
  1005035: {
    id: '1005035',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'During the week you are claiming, did you receive any type of separation pay?',
      es: 'Durante la semana que usted reclama, ¿recibió algún tipo de pago por separación?',
    },
  },
  1005201: {
    id: '1005201',
    inputType: 'numericInt',
    label: {
      en: 'Enter the total hours worked, not including minutes.',
      es: 'Ingrese el total de horas trabajadas, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  1005022: {
    id: '1005022',
    inputType: 'numericInt',
    label: {
      en: 'Enter the total hours worked, not including minutes.',
      es: 'Ingrese el total de horas trabajadas, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  1005101: {
    id: '1005101',
    inputType: 'numericFloat',
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents.',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos.',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  1005017: {
    id: '1005017',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Have you given a statement to the UI Hotline concerning this separation?',
      es: '¿Ha dado una declaración a la línea directa de UI sobre esta separación?',
    },
  },
  1005002: {
    id: '1005002',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Self-Employment',
      es: 'Trabajo por cuenta propia',
    },
    divider: true,
    horizontalLayoutEnabled: true,
    headerText: {
      en: 'During this week did you earn wages in any of the following?',
      es: 'Durante esta semana, ¿ganó usted salario en alguno de los siguientes?',
    },
  },
  1005202: {
    id: '1005202',
    inputType: 'numericInt',
    label: {
      en: 'Enter the total hours worked, not including minutes.',
      es: 'Ingrese el total de horas trabajadas, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  1005102: {
    id: '1005102',
    inputType: 'numericFloat',
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents.',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos.',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  1005003: {
    id: '1005003',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Military Drill',
      es: 'Simulacro militar',
    },
    horizontalLayoutEnabled: true,
  },
  1005203: {
    id: '1005203',
    inputType: 'numericInt',
    label: {
      en: 'Enter the total hours you were at drill, not including minutes.',
      es: 'Ingrese el total de horas que estuvo en el simulacro, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  1005103: {
    id: '1005103',
    inputType: 'numericFloat',
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents.',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos.',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  1005004: {
    id: '1005004',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Holiday Pay',
      es: 'Días Feriados Pagos',
    },
    horizontalLayoutEnabled: true,
  },
  1005104: {
    id: '1005104',
    inputType: 'numericFloat',
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents.',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos.',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  1005005: {
    id: '1005005',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Vacation Pay',
      es: 'Vacaciones Pagas',
    },
    horizontalLayoutEnabled: true,
  },
  1005105: {
    id: '1005105',
    inputType: 'numericFloat',
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents.',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos.',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  1005015: {
    id: '1005015',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Sick Leave',
      es: 'Baja por enfermedad',
    },
    horizontalLayoutEnabled: true,
  },
  1005115: {
    id: '1005115',
    inputType: 'numericFloat',
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents.',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos.',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  1005025: {
    id: '1005025',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'PTO',
      es: 'Tiempo remunerado',
    },
    horizontalLayoutEnabled: true,
  },
  1005125: {
    id: '1005125',
    inputType: 'numericFloat',
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents.',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos.',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  1005045: {
    id: '1005045',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Bonus Pay',
      es: 'Pago extra',
    },
    horizontalLayoutEnabled: true,
  },
  1005145: {
    id: '1005145',
    inputType: 'numericFloat',
    label: {
      en: 'Enter the gross bonus pay before deductions. Enter the amount in dollars and cents',
      es: 'Ingrese el pago de bonificación bruto antes de las deducciones. Ingrese el monto en dólares y centavos',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  1005051: {
    id: '1005051',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Have you notified the UI Hotline concerning your self-employment, military drill, holiday pay, vacation pay, sick pay, PTO, or bonus pay?',
      es: '¿Ha notificado a la línea directa de UI sobre su trabajo por cuenta propia, ejercicios militares, pago de días festivos, pago de vacaciones, pago por enfermedad, PTO o pago de bonificaciones?',
    },
    divider: true,
  },
  2005001: {
    id: '2005001',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Were you able and available for work each day of the week?',
      es: '¿Estuvo capaz y disponible para trabajar todos los días de la semana?',
    },
    divider: true,
  },
  2005011: {
    id: '2005011',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  3005001: {
    id: '3005001',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'During the week did you refuse any work, a referral to work, a recall to a former employer or failed to appear for a pre-employment drug screen?',
      es: 'Durante la semana, ¿rechazó algún trabajo, una recomendación para trabajar, un llamado a un antiguo empleador o no se presentó a una prueba de detección de drogas previa al empleo?',
    },
    divider: true,
  },
  3005011: {
    id: '3005011',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  3005002: {
    id: '3005002',
    inputType: 'yesNoRadioGroup',
    label: 'continuedClaim.questions.q12_32.label',
    richLabel: true,
    divider: true,
  },
  3005021: {
    id: '3005021',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  3005003: {
    id: '3005003',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'During this week did you start school or begin a training program?',
      es: '¿Durante esta semana empezaste a ir a la escuela o comenzaste un programa de capacitación?',
    },
    divider: true,
  },
  3005031: {
    id: '3005031',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  4005001: {
    id: '4005001',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'During this week did you apply for or begin receiving any retirement pay or separation pay, not including Social Security?',
      es: '¿Durante esta semana solicitó o comenzó a recibir algún pago de jubilación o pago de separación, sin incluir el Seguro Social?',
    },
    divider: true,
  },
  4005011: {
    id: '4005011',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  5005001: {
    id: '5005001',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'During this week, did you make your five job contacts?',
      es: '¿Durante esta semana hiciste tus cinco contactos laborales?',
    },
    divider: true,
  },
  5005011: {
    id: '5005011',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  6005001: {
    id: '6005001',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Has your mailing address changed during this week?',
      es: '¿Ha cambiado su dirección postal durante esta semana?',
    },
    divider: true,
  },
  6005011: {
    id: '6005011',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Have you reported this change to your local office?',
      es: '¿Ha reportado este cambio a su oficina local?',
    },
    divider: true,
  },
  6005002: {
    id: '6005002',
    inputType: 'yesNoRadioGroup',
    label: {
      en: 'Did you start working full time after this week?',
      es: '¿Empezaste a trabajar a tiempo completo después de esta semana?',
    },
    divider: true,
  },
  C: {
    id: 'C',
    inputType: 'yesNoRadioGroup',
    label: 'continuedClaim.questions.q17_42.label',
    richLabel: true,
    divider: true,
  },
};

export default CONTINUED_CLAIM_QUESTIONS;
