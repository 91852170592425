import apiRequest from './wrapper';

/**
 * @method  : POST
 * @path    : /users
 * @desc    : create new user
 */
export const createUser = (values) => {
  return new Promise((resolve, reject) => {
    apiRequest('post', '/users/', { data: values })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = '401 createUser: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = '404 createUser: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = '500 createUser: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          reject(new Error('createUser: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};

/**
 * @method  : GET
 * @path    : /users/me
 * @desc    : get user's data
 */
export const getUserData = () => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/users/me')
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = '401 getUserData: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = '404 getUserData: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = '500 getUserData: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          reject(new Error('getUserData: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};

/**
 * @method  : POST
 * @path    : /users/me/resume
 * @desc    : upload user's resume
 */
export const uploadUserResume = (values) => {
  return new Promise((resolve, reject) => {
    apiRequest('post', '/users/me/resume/', { data: values })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'uploadUserResume: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'uploadUserResume: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'uploadUserResume: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          reject(new Error('uploadUserResume: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};

/**
 * @method  : PUT
 * @path    : /users/me
 * @desc    : update user's data
 */
export const updateUserData = (values) => {
  return new Promise((resolve, reject) => {
    apiRequest('put', '/users/me', { data: values })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'updateUserData: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'updateUserData: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'updateUserData: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          reject(new Error('updateUserData: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};

/**
 * @method  : GET
 * @path    : /users/me/settings
 * @desc    : get user's settings
 */
export const getUserSettings = (pathParam, params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/users/me/settings', pathParam, { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'getUserSettings: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'getUserSettings: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'getUserSettings: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          reject(new Error('getUserSettings: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};
