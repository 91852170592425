import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Form, Formik, getIn, useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import * as Yup from 'yup';
import { Add, Download, Edit, ArticleTwoTone, Close, MoreVert, Delete } from '@mui/icons-material';
import { useIntl } from 'react-intl';

import { Experience, UseResumeDataQuery } from 'lib/hooks/useResumeDataQuery';
import TextInputField from 'components/inputs/TextInputField';
import PhoneInputField from 'components/inputs/PhoneInputField';
import ExperienceWalletSkillsAutocomplete from 'components/forms/experienceWallet/ExperienceWalletSkillsAutocomplete';
import ExperienceWalletForm from 'components/forms/experienceWallet/ExperienceWalletForm';

const emptyExperience = {
  id: '',
  type: '',
  field: null,
  fields: [],
  skills: [],
  description: '',
  employer_provider: '',
  start_date: null,
  end_date: null,
};

const headerSchema = Yup.object().shape({
  header: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format'),
    phone_number: Yup.string(),
    url: Yup.string().url('URL must be a valid URL'),
    location: Yup.string(),
  }),
});

const summarySchema = Yup.object().shape({
  summary: Yup.string().max(500, 'Summary cannot exceed 500 characters'),
});

function EditableCard({
  title,
  subtitle,
  onEdit,
  onDelete,
}: {
  title: string;
  subtitle: string;
  onEdit: () => void;
  onDelete: () => void;
}) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper variant="outlined" sx={{ px: 2, py: 1.5, display: 'flex', alignItems: 'center' }}>
      <Box flexGrow={1}>
        <Typography fontWeight="bold" variant="body1">
          {title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {subtitle}
        </Typography>
      </Box>

      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title={intl.formatMessage({ id: 'experience.row.actions' })}>
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? 'actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <MoreVert sx={{ width: 24, height: 24 }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="actions-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={onEdit} id="edit-experience-resume-button">
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            {intl.formatMessage({ id: 'experience.row.edit' })}
          </MenuItem>
          <MenuItem onClick={onDelete} id="delete-resume-experience-button">
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            {intl.formatMessage({ id: 'experience.row.delete' })}
          </MenuItem>
        </Menu>
      </Box>
    </Paper>
  );
}

const FormStateUpdater = ({
  formValues,
  updateParentState,
}: {
  formValues: UseResumeDataQuery['data'];
  updateParentState: (newValues: UseResumeDataQuery['data']) => void;
}) => {
  const { values } = useFormikContext<UseResumeDataQuery['data']>();

  const debouncedUpdate = debounce((newValues: UseResumeDataQuery['data']) => {
    updateParentState(newValues);
  }, 250);

  // Reference to track if the updater has run at least once
  const hasUpdatedOnceRef = useRef(false);

  useEffect(() => {
    // Use initial data (`formValues`) for the first update if `values` is empty
    if (!hasUpdatedOnceRef.current) {
      const initialData = {
        ...formValues,
        ...values,
      };
      debouncedUpdate(initialData);
      hasUpdatedOnceRef.current = true;
    } else if (values.header !== formValues.header) {
      // Run updates for subsequent changes
      debouncedUpdate({ ...formValues, header: values.header });
    }

    return () => {
      debouncedUpdate.cancel();
    };
  }, [values, debouncedUpdate, formValues]);

  return null;
};

const EDITING_ITEM_INITIAL_STATE = {
  type: 'work',
  index: -1,
  item: null,
};

function ResumeEditor({
  formValues,
  handleDownload,
  updateFormValues,
  resumeInitialValues,
}: {
  formValues: UseResumeDataQuery['data'];
  handleDownload: () => void;
  updateFormValues: (newValues: UseResumeDataQuery['data']) => void;
  resumeInitialValues: UseResumeDataQuery['data'];
}) {
  const [skillsOpen, setSkillsOpen] = useState(false);
  const [editingItem, setEditingItem] = useState<{ type: string; index: number; item: Experience | null }>(
    EDITING_ITEM_INITIAL_STATE
  );
  const [experienceModalOpen, setExperienceModalOpen] = useState(false);

  function handleEditExperience(index: number, type: string) {
    const sectionIndex = type === 'work' ? 2 : 3;
    setExperienceModalOpen(true);
    setEditingItem({
      index,
      type,
      item: (formValues.sections[sectionIndex].items as Experience[])[index],
    });
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 1,
        }}
      >
        <Typography variant="h1">
          <ArticleTwoTone sx={{ fontSize: 24, position: 'relative', top: 2 }} /> Edit Your Resume
        </Typography>

        <Button startIcon={<Download />} variant="contained" onClick={handleDownload} id="download-resume-button">
          Download Resume
        </Button>
      </Box>

      <Typography gutterBottom sx={{ mb: 2 }}>
        Add, change, or delete content from your resume to fit your job search goals. Your edits are automatically
        saved. You can download your resume, then return to update it, as often as you like.
      </Typography>

      <Formik
        initialValues={{ header: resumeInitialValues.header }}
        validationSchema={headerSchema}
        onSubmit={() => {}}
      >
        {({ errors, touched }) => (
          <Form>
            <FormStateUpdater formValues={formValues} updateParentState={updateFormValues} />
            {/* Header Section */}
            <Box mb={2}>
              <Typography variant="h4" component="h2">
                Contact Information
              </Typography>

              <Typography component="p" variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                Use an email or phone number you check often.
              </Typography>

              <Grid container columnSpacing={2}>
                <Grid item xs={12} md={6}>
                  <TextInputField
                    name="header.name"
                    label={'Your Name'}
                    error={getIn(touched, 'header.name') && getIn(errors, 'header.name')}
                    id="your-name-resume-field"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <PhoneInputField
                    name="header.phone_number"
                    label={'Phone'}
                    error={getIn(touched, 'header.phone_number') && getIn(errors, 'header.phone_number')}
                    id="phone-resume-field"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextInputField
                    name="header.email"
                    label={'Email'}
                    error={getIn(touched, 'header.email') && getIn(errors, 'header.email')}
                    id="email-resume-field"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextInputField
                    name="header.location"
                    label={'Location'}
                    error={getIn(touched, 'header.location') && getIn(errors, 'header.location')}
                    placeholder="City, State"
                    id="location-resume-field"
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextInputField
                    name="header.url"
                    label={'Link to Profile or Portfolio'}
                    error={getIn(touched, 'header.url') && getIn(errors, 'header.url')}
                    placeholder="your.portfolio.com"
                    helperText={'Include a link to showcase your work.'}
                    id="profile-portfolio-resume-field"
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>

      {formValues.sections.map((section) => {
        switch (section.type) {
          case 'summary':
            return (
              <Formik
                key={section.type}
                initialValues={{ summary: '' }}
                validationSchema={summarySchema}
                onSubmit={() => {}}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Box mb={2}>
                      <Typography variant="h4" component="h2">
                        Summary
                      </Typography>

                      <Typography component="p" variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                        Write 2-3 sentences about your skills, experience, and goals. Make it short but interesting to
                        grab attention.
                      </Typography>

                      <FormControl>
                        <OutlinedInput
                          id="statement-resume-field"
                          aria-labelledby="statement-resume-field"
                          name="summary"
                          value={formValues ? formValues.sections[0].items[0] : ''}
                          onChange={(event) => {
                            const prev = structuredClone(formValues);
                            prev.sections[0].items[0] = event.target.value;
                            updateFormValues(prev);
                          }}
                          multiline
                          inputComponent="textarea"
                          rows={3}
                          error={getIn(touched, 'summary') && getIn(errors, 'summary')}
                        />
                      </FormControl>
                    </Box>
                  </Form>
                )}
              </Formik>
            );
          case 'skills':
            return (
              <Box mb={2} key={section.type}>
                <Typography variant="h4" component="h2">
                  Top Skills
                </Typography>

                <Typography component="p" variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                  Select up to five skills that define your top strengths.
                </Typography>

                <ExperienceWalletSkillsAutocomplete
                  value={formValues ? (formValues.sections[1].items as Experience[]) : []}
                  onChange={(_, newValues) => {
                    const maxSkillsAllowed = 5;

                    // Prevent passing the top skills limit
                    if (newValues.length > maxSkillsAllowed) {
                      return;
                    }

                    const prev = structuredClone(formValues);
                    prev.sections[1].items = newValues;
                    updateFormValues(prev);
                  }}
                  open={skillsOpen}
                  setOpen={setSkillsOpen}
                  id="skills-resume-field"
                />
              </Box>
            );
          case 'work_experience':
            return (
              <Box mb={2} key={section.type}>
                <Typography variant="h4" component="h2">
                  Work Experience
                </Typography>

                <Typography component="p" variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                  Every experience builds your skills, including jobs, internships, and apprenticeships.
                </Typography>

                <Stack spacing={1} mb={1}>
                  {section.items.map((work, index) => (
                    <EditableCard
                      key={index}
                      title={work.employer_provider}
                      subtitle={work.description}
                      onEdit={() => handleEditExperience(index, 'work')}
                      onDelete={() => {
                        const prev = structuredClone(formValues);
                        prev.sections[2].items = (prev.sections[2].items as Experience[]).filter((_, i) => i !== index);
                        updateFormValues(prev);
                      }}
                    />
                  ))}
                </Stack>

                <Button
                  onClick={() => {
                    setExperienceModalOpen(true);
                    setEditingItem(EDITING_ITEM_INITIAL_STATE);
                  }}
                  startIcon={<Add />}
                  id="add-experience-resume-button"
                >
                  Add Experience
                </Button>
              </Box>
            );
          case 'others':
            return (
              <Box mb={2} key={section.type}>
                <Typography variant="h4" component="h2">
                  Education and Certifications
                </Typography>

                <Typography component="p" variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                  Education includes all learning and skill building. Add degrees, certificates, licenses, or courses.
                </Typography>

                <Stack spacing={1} mb={1}>
                  {section.items.map((education, index) => (
                    <EditableCard
                      key={index}
                      title={education.employer_provider}
                      subtitle={education.description}
                      onEdit={() => handleEditExperience(index, 'education')}
                      onDelete={() => {
                        const prev = structuredClone(formValues);
                        prev.sections[3].items = (prev.sections[3].items as Experience[]).filter((_, i) => i !== index);
                        updateFormValues(prev);
                      }}
                    />
                  ))}
                </Stack>

                <Button
                  onClick={() => {
                    setExperienceModalOpen(true);
                    setEditingItem({
                      ...EDITING_ITEM_INITIAL_STATE,
                      type: 'education',
                    });
                  }}
                  startIcon={<Add />}
                  id="add-education-resume-button"
                >
                  Add Education
                </Button>
              </Box>
            );

          default:
            return null;
        }
      })}

      <Typography variant="h4" component="h2">
        Put your resume to work!
      </Typography>

      <Typography component="p" variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
        <Link sx={{ cursor: 'pointer' }} onClick={handleDownload} id="download-resume-link">
          Download your resume
        </Link>{' '}
        and include it with your job applications. You can return to edit it for new roles or add new work or learning
        experiences.
      </Typography>

      <Dialog open={experienceModalOpen || editingItem.index !== -1} onClose={() => {}}>
        <Box sx={{ px: 3, py: 2, maxWidth: 464, boxSizing: 'border-box' }}>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setExperienceModalOpen(false);
              setEditingItem(EDITING_ITEM_INITIAL_STATE);
            }}
            sx={{
              position: 'absolute',
              right: 6,
              top: 6,
              color: 'black',
              fontSize: 34,
            }}
          >
            <Close />
          </IconButton>
          <ExperienceWalletForm
            appendItem={editingItem.index !== -1 ? null : () => {}}
            replaceItem={editingItem.index !== -1 ? () => {} : null}
            currentIndex={editingItem.index}
            experience={editingItem.index !== -1 ? { ...emptyExperience, ...editingItem.item } : emptyExperience}
            experiences={[]}
            setCurrentContent={() => {
              setExperienceModalOpen(false);
              setEditingItem(EDITING_ITEM_INITIAL_STATE);
            }}
            onSubmit={(experienceFormValues) => {
              const isEdit = editingItem.index !== -1;
              const sectionIndex = editingItem.type === 'work' ? 2 : 3;
              const prev = structuredClone(formValues);

              if (isEdit) {
                prev.sections[sectionIndex].items[editingItem.index] = experienceFormValues;
              } else {
                prev.sections[sectionIndex].items.push(experienceFormValues);
              }
              updateFormValues(prev);
              setExperienceModalOpen(false);
              setEditingItem({ index: -1, item: null, type: 'work' });
            }}
          />
        </Box>
      </Dialog>
    </Box>
  );
}

export default ResumeEditor;
