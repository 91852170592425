import { type z } from 'zod';
import { HTTPMethod, api, EmptyObjectSchema, EmptyRequestSchema, NullResponseSchema } from './client';
import {
  MatchClaimantAccountRequestSchema,
  SaveContinuousClaimRecordRequestSchema,
  SaveContinuousClaimRecordResponseSchema,
  ValidateAndInitiateClaimResponseSchema,
  GetClaimantInfoResponseSchema,
  GetMailingAddressResponseSchema,
  VerifyRoutingNumberRequestSchema,
  VerifyRoutingNumberResponseSchema,
  SubmitDirectDepositSchema,
  GetPaymentInfoRequestSchema,
  GetBankingInfoResponseSchema,
} from 'lib/schemas/ar-ui';

export const matchClaimantAccount = api<
  z.infer<typeof MatchClaimantAccountRequestSchema>,
  z.infer<typeof EmptyObjectSchema>
>({
  method: HTTPMethod.POST,
  path: '/ui/match_claimant_account',
  requestSchema: MatchClaimantAccountRequestSchema,
  responseSchema: EmptyObjectSchema,
});

export const getClaimantInfo = api<z.infer<typeof EmptyObjectSchema>, z.infer<typeof GetClaimantInfoResponseSchema>>({
  method: HTTPMethod.POST,
  path: '/ui/get_claimant_info',
  requestSchema: EmptyObjectSchema,
  responseSchema: GetClaimantInfoResponseSchema,
});

export const getMailingAddressInfo = api<
  z.infer<typeof EmptyObjectSchema>,
  z.infer<typeof GetMailingAddressResponseSchema>
>({
  method: HTTPMethod.POST,
  path: '/ui/get_mailing_address',
  requestSchema: EmptyObjectSchema,
  responseSchema: GetMailingAddressResponseSchema,
});

export const saveMailingAddressInfo = api<
  z.infer<typeof GetMailingAddressResponseSchema>,
  z.infer<typeof EmptyObjectSchema>
>({
  method: HTTPMethod.POST,
  path: '/ui/set_mailing_address',
  requestSchema: GetMailingAddressResponseSchema,
  responseSchema: EmptyObjectSchema,
});

export const validateAndInitiateClaim = api<
  z.infer<typeof EmptyRequestSchema>,
  z.infer<typeof ValidateAndInitiateClaimResponseSchema>
>({
  method: HTTPMethod.POST,
  path: '/ui/validate_and_initiate_claim',
  requestSchema: EmptyRequestSchema,
  responseSchema: ValidateAndInitiateClaimResponseSchema,
});

export const saveContinuousClaimRecord = api<
  z.infer<typeof SaveContinuousClaimRecordRequestSchema>,
  z.infer<typeof SaveContinuousClaimRecordResponseSchema>
>({
  method: HTTPMethod.POST,
  path: '/ui/save_continuous_claim_record',
  requestSchema: SaveContinuousClaimRecordRequestSchema,
  responseSchema: SaveContinuousClaimRecordResponseSchema,
});

export const getBankingInfo = api<
  z.infer<typeof GetPaymentInfoRequestSchema>,
  z.infer<typeof GetBankingInfoResponseSchema>
>({
  method: HTTPMethod.POST,
  path: '/ui/get_payment_info',
  requestSchema: GetPaymentInfoRequestSchema,
  responseSchema: GetBankingInfoResponseSchema,
});

export const verifyRoutingNumber = api<
  z.infer<typeof VerifyRoutingNumberRequestSchema>,
  z.infer<typeof VerifyRoutingNumberResponseSchema>
>({
  method: HTTPMethod.POST,
  path: '/ui/verify_routing_number',
  requestSchema: VerifyRoutingNumberRequestSchema,
  responseSchema: VerifyRoutingNumberResponseSchema,
});

export const submitDirectDeposit = api<z.infer<typeof SubmitDirectDepositSchema>, z.infer<typeof NullResponseSchema>>({
  method: HTTPMethod.POST,
  path: '/ui/set_payment_direct_deposit',
  requestSchema: SubmitDirectDepositSchema,
  responseSchema: NullResponseSchema,
});

export const submitDebidCard = api<z.infer<typeof EmptyObjectSchema>, z.infer<typeof NullResponseSchema>>({
  method: HTTPMethod.POST,
  path: '/ui/set_payment_debit_card',
  requestSchema: EmptyObjectSchema,
  responseSchema: NullResponseSchema,
});
