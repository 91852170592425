import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';

/* i18n Dependencies */
import { IntlProvider } from 'react-intl';
import getUserLocale from 'get-user-locale';
import { languages, defaultLanguage } from 'i18n/config';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from 'app/slices/appSlice';

/* API */
import { defaultQueryFn } from 'lib/API/wrapper';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
      retry: (_, error) => {
        // Deactivate retry logic globally when the user is not found (400 error)
        return error?.message !== 'User not found';
      },
    },
  },
});

function IntlApp() {
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.app);

  const options = {
    locale: locale,
    defaultLocale: defaultLanguage,
    messages: require(`./i18n/${locale}.json`),
  };

  useEffect(() => {
    // Lookup prior language preference from local storage.
    const userLocale = getUserLocale() && getUserLocale().slice(0, 2) ? getUserLocale().slice(0, 2) : false;
    const lsLocale =
      typeof window !== 'undefined' && localStorage && localStorage.getItem('lng')
        ? localStorage.getItem('lng')
        : false;
    const browserLocale = languages.includes(userLocale) ? userLocale : false;
    if (lsLocale) {
      dispatch(setLocale(lsLocale));
    } else if (browserLocale) {
      dispatch(setLocale(browserLocale));
    }
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={locale} defaultLocale={defaultLanguage} messages={options.messages}>
        <App locale={locale} />
      </IntlProvider>
    </QueryClientProvider>
  );
}

export default IntlApp;
