import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { TextField, Button, Box, CircularProgress } from '@mui/material';

/* API */
import { apiRequest } from 'lib/API';
import { usePostUpdateWorkSearch } from 'lib/hooks/workSearchQueries';

import PhoneNumberInput from 'components/forms/PhoneNumberInput';
import { DatePicker } from '@mui/x-date-pickers';

/**
 * i18n
 */
import { useIntl } from 'react-intl';

/* Analytics */
import { trackEvent } from 'lib/analytics';

import styles from 'styles/apply-job-form.module.scss';

import BrowseDocuments from 'components/BrowseDocuments';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function InterviewWithEmployer({ setOpen, selectedActivity, editting = false, week, onSubmit }) {
  const [submitting, setSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [refreshConter, setRefreshConter] = useState(0);
  const [documentsLinks, setDocumentsLinks] = useState([]);
  const [dateError, setDateError] = useState('');
  const intl = useIntl();
  const postUpdateWorkSearchMutation = usePostUpdateWorkSearch(editting, () => {
    setOpen(false);
    setSubmitting(false);
  });

  useEffect(() => {
    setRefreshConter(refreshConter + 1);
    if (selectedActivity && selectedActivity.upload_documents && selectedActivity.upload_documents.length > 0) {
      setDocumentsLinks([...selectedActivity.upload_documents]);
    } else {
      setDocumentsLinks([]);
    }
  }, [selectedActivity, editting]);

  useEffect(() => {
    const documentsWithNoLinks = documentsLinks.filter((d) => !d.downloadUrl);
    if (documentsWithNoLinks.length > 0) {
      documentsLinks.forEach((doc, idx) => {
        if (doc.downloadUrl) {
          return;
        }
        apiRequest(
          'get',
          `/users/me/worksearch/${encodeURIComponent(selectedActivity.worksearch_id)}?file_uuid=${encodeURIComponent(
            doc.file_uuid
          )}`
        ).then((res) => {
          setDocumentsLinks([
            ...documentsLinks.slice(0, idx),
            { ...doc, downloadUrl: res.activity?.download_url },
            ...documentsLinks.slice(idx + 1),
          ]);
        });
      });
    }
  }, [JSON.stringify(documentsLinks)]);

  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; // eslint-disable-line no-useless-escape
  const emailRegExp = /^\S+@\S+\.\w\w+$/; // eslint-disable-line no-useless-escape

  const validationSchema = yup.object({
    company_name: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    interviewer_name: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    interviewer_phone_number: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .matches(phoneRegExp, intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    interviewer_email: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .matches(emailRegExp, intl.formatMessage({ id: 'forms.onboarding.emailValidation' })),
    date_of_interview: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    result_of_interview: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    position_interviewed_for: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
  });

  const defaultValues = {
    company_name: '',
    interviewer_name: '',
    interviewer_phone_number: '',
    interviewer_email: '',
    upload_documents: [],
    notes: '',
    date_of_interview: null,
    position_interviewed_for: '',
    result_of_interview: '',
    draft: false,
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={
        selectedActivity
          ? {
              ...selectedActivity,
              upload_documents: [],
              date_of_interview: new Date(
                selectedActivity.date_of_interview.slice(0, 4),
                selectedActivity.date_of_interview.slice(5, 7) - 1,
                selectedActivity.date_of_interview.slice(8, 10)
              ),
            }
          : defaultValues
      }
      onSubmit={async (values) => {
        setSubmitting(true);
        let year = values.date_of_interview.getFullYear();
        let month = values.date_of_interview.getMonth() + 1;
        let day = values.date_of_interview.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        const vals4submit = {
          ...values,
          activity_type: 'interview_with_employer',
          activity_date: `${year}-${month}-${day}`,
          date_of_interview: `${year}-${month}-${day}`,
        };
        vals4submit.upload_documents = values.upload_documents.map((doc) => ({
          filename: doc.name,
          upload_contents: doc.contents,
        }));
        if (onSubmit && typeof onSubmit === 'function') {
          return onSubmit(vals4submit);
        }
        if (editting) {
          trackEvent('WEEK_VIEWACTIVITY_SAVE');
        } else {
          trackEvent('WEEK_SAVEACTIVITY_INTERVIEW');
        }
        postUpdateWorkSearchMutation.mutate(vals4submit);
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => {
        function isValidDate(d) {
          return d instanceof Date && !isNaN(d);
        }
        const handleDateChange = (date) => {
          const minDate = new Date(week.from.slice(0, 4), week.from.slice(5, 7) - 1, week.from.slice(8, 10));
          const maxDate = new Date(week.to.slice(0, 4), week.to.slice(5, 7) - 1, week.to.slice(8, 10));
          if (isValidDate(date)) {
            setFieldValue('date_of_interview', date);
            if (date < minDate || date > maxDate) {
              setDateError('The selected date is outside the valid range');
            } else {
              setDateError('');
            }
          } else {
            let error = intl.formatMessage({ id: 'jobSearchActivity.validation.invalidDateFormat' });
            if (Object.is(date, null)) {
              error = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            setDateError(error);
            setFieldValue('date_of_interview', '');
          }
        };
        useEffect(() => {
          if (touched.date_of_interview) {
            handleDateChange(values.date_of_interview);
          }
        }, [touched.date_of_interview]);
        return (
          <form onSubmit={handleSubmit} className={styles.loginFlow__form}>
            {submitting ? (
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <div className={styles.fields}>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      disabled={submitting || (editting && !editMode)}
                      error={touched.company_name && Boolean(errors.company_name)}
                      fullWidth
                      helperText={touched.company_name && errors.company_name}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.companyName' })}
                      margin="normal"
                      name="company_name"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.company_name}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.companyName' }),
                      }}
                      id="company-interview-work-search-field"
                    />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <TextField
                      InputLabelProps={{ required: true }}
                      error={touched.interviewer_name && Boolean(errors.interviewer_name)}
                      disabled={submitting || (editting && !editMode)}
                      fullWidth
                      helperText={touched.interviewer_name && errors.interviewer_name}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.interviewerName' })}
                      margin="normal"
                      name="interviewer_name"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.interviewer_name}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.interviewerName' }),
                      }}
                      id="name-interview-work-search-field"
                    />
                    <HelpOutlineIcon className={styles.helperTextIcon} />
                    <span className={cn(styles.fieldDescription, styles.fieldDescriptionSemiClose)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.contactNameSub' })}
                    </span>
                  </div>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      disabled={submitting || (editting && !editMode)}
                      error={touched.interviewer_phone_number && Boolean(errors.interviewer_phone_number)}
                      fullWidth
                      helperText={touched.interviewer_phone_number && errors.interviewer_phone_number}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.interviewerPhone' })}
                      name="interviewer_phone_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.interviewer_phone_number}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.interviewerPhone' }),
                      }}
                      InputProps={{
                        inputComponent: PhoneNumberInput,
                      }}
                      id="phone-interview-work-search-field"
                    />
                  </div>
                  <div>
                    <TextField
                      disabled={submitting || (editting && !editMode)}
                      error={touched.interviewer_email && Boolean(errors.interviewer_email)}
                      fullWidth
                      helperText={touched.interviewer_email && errors.interviewer_email}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.interviewerEmail' })}
                      name="interviewer_email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.interviewer_email}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.interviewerEmail' }),
                      }}
                      id="email-interview-work-search-field"
                    />
                  </div>
                  <Box sx={{ position: 'relative' }}>
                    <DatePicker
                      onError={(newError) => {
                        if (['minDate', 'maxDate'].indexOf(newError) > -1) {
                          setDateError(intl.formatMessage({ id: 'jobSearchActivity.form.dateOutOfRange' }));
                        } else {
                          setDateError(newError);
                        }
                      }}
                      disabled={submitting || (editting && !editMode)}
                      label={`${intl.formatMessage({ id: 'jobSearchActivity.form.dateOfInterview' })} *`}
                      minDate={new Date(week.from.slice(0, 4), week.from.slice(5, 7) - 1, week.from.slice(8, 10))}
                      maxDate={new Date(week.to.slice(0, 4), week.to.slice(5, 7) - 1, week.to.slice(8, 10))}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                          helperText:
                            touched.date_of_interview && errors.date_of_interview
                              ? errors.date_of_interview
                              : dateError,
                          error: dateError || (touched.date_of_interview && Boolean(errors.date_of_interview)),
                          id: 'date-interview-work-search-field',
                        },
                      }}
                      onChange={(newValue) => {
                        setFieldValue('date_of_interview', newValue);
                      }}
                      value={values.date_of_interview}
                    />
                    <HelpOutlineIcon
                      sx={{
                        color: (theme) => theme.palette.grey['800'],
                        fontSize: 16,
                        position: 'absolute',
                        top: { xs: 61, md: 52 },
                        left: 0,
                      }}
                    />
                    <span className={cn(styles.fieldDescription, styles.fieldDescriptionSemiClose)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.dateRange' })}
                    </span>
                  </Box>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      error={touched.position_interviewed_for && Boolean(errors.position_interviewed_for)}
                      disabled={submitting || (editting && !editMode)}
                      fullWidth
                      helperText={touched.position_interviewed_for && errors.position_interviewed_for}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.positionInterviewedFor' })}
                      name="position_interviewed_for"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.position_interviewed_for}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.positionInterviewedFor' }),
                      }}
                      id="position-interview-work-search-field"
                    />
                  </div>
                </div>
                <div className={styles.checksAndRadios} style={{ paddingTop: 16 }}>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      error={touched.result_of_interview && Boolean(errors.result_of_interview)}
                      disabled={submitting || (editting && !editMode)}
                      fullWidth
                      helperText={touched.result_of_interview && errors.result_of_interview}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.resultOfInterview' })}
                      name="result_of_interview"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.result_of_interview}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfInterview' }),
                      }}
                      id="result-interview-work-search-field"
                    />
                  </div>
                  <div className={styles.checks}></div>
                </div>
                <div className={styles.boxes}>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.uploadDocuments' })}
                      </span>
                    </Box>
                    <Field
                      name="upload_documents"
                      disabled={submitting || (editting && !editMode)}
                      component={BrowseDocuments}
                      label={'jobSearchActivity.form.documentHint'}
                      buttonId="browse-documents-interview-work-search-button"
                    />
                    <div className={styles.downloadLinks}>
                      {documentsLinks.map((doc) =>
                        doc.downloadUrl ? (
                          <a href={doc.downloadUrl} key={doc.downloadUrl}>
                            {doc.filename}
                          </a>
                        ) : (
                          <span key={JSON.stringify(doc)}>...</span>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      </span>
                    </Box>
                    <TextField
                      disabled={submitting || (editting && !editMode)}
                      placeholder={intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      multiline
                      rows={5}
                      variant="outlined"
                      InputProps={{ style: { fontSize: 12, height: '112px' } }}
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="notes"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
                      }}
                      id="notes-interview-work-search-field"
                    />
                  </div>
                </div>
              </>
            )}
            <Box display="flex" justifyContent="space-between" mt={4}>
              <div />
              <Box display="flex">
                <Box mr={1}>
                  <Button
                    type="button"
                    disabled={submitting}
                    size="large"
                    onClick={() => setOpen(false)}
                    variant="outlined"
                    color="secondary"
                    id="cancel-interview-work-search-button"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })
                    )}
                  </Button>
                </Box>
                {editting && !editMode ? (
                  <Button
                    color="secondary"
                    type="button"
                    disabled={submitting}
                    size="large"
                    variant="contained"
                    onClick={(evt) => {
                      evt.preventDefault();
                      trackEvent('WEEK_VIEWACTIVITY_EDIT');
                      setEditMode(true);
                    }}
                    id="edit-interview-work-search-button"
                  >
                    {intl.formatMessage({ id: 'layout.header.edit' })}
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    disabled={submitting || Boolean(dateError)}
                    size="large"
                    type="submit"
                    variant="contained"
                    id="save-interview-work-search-button"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.save' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.save' })
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

InterviewWithEmployer.propTypes = {
  setOpen: PropTypes.func,
  selectedActivity: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  editting: PropTypes.bool,
  week: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default InterviewWithEmployer;
