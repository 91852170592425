import apiRequest from './wrapper';

/**
 * @method  : GET
 * @path    :  /users/me/jobs/{soc}/{guid}
 * @desc    : get details of a single job with GUID guid and assigned SOC code soc.
 */
export const getJob = (pathParam, params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/users/me/jobs/', { params }, pathParam)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'getJob: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'getJob: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'getJob: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          reject(new Error('getJob: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};

/**
 * @method  : GET
 * @path    : /users/me/jobs
 * @desc    : get user's recommended jobs.
 */
export const getRecommendedJobs = (pathParam, params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/users/me/jobs/', { params }, pathParam)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @method  : GET
 * @path    :  /users/me/jobs/{soc}/{guid}
 * @desc    : get details of a single job with GUID guid and assigned SOC code soc.
 */
export const updateJob = (job, rating) => {
  return new Promise((resolve, reject) => {
    apiRequest('put', `/users/me/jobs/${job.soc}/${job.guid}`, { data: { rating } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'updateJob: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'updateJob: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'updateJob: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          reject(new Error('updateJob: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};
