import { useState } from 'react';

import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { submitDirectDeposit } from 'lib/API/ar-ui';

/* API */
import { verifyRoutingNumber } from 'lib/API';

/* i18n */
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

export default function DirectDepositEnrollForm({ setPaymentInfoData, submitting, setSubmitting, setOpen }) {
  const intl = useIntl();
  const [validatingRoutingNumber, setValidatingRoutingNumber] = useState(false);
  const [validatedBankName, setValidatedBankName] = useState('');
  const [routingNumberInvalid, setRoutingNumberInvalid] = useState(false);
  const [accType, setAccType] = useState('password');
  const [confirmAccType, setConfirmAccType] = useState('password');
  const submitDirectDepositMutation = useMutation({ mutationFn: (values) => submitDirectDeposit(values) });
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const validationSchema = yup.object({
    account_type: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    routing_number: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    confirm_routing_number: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    account_number: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .min(5, intl.formatMessage({ id: 'fileClaim.bankingForm.xDigitsValidation' }, { n: '5-16' }))
      .max(16, intl.formatMessage({ id: 'fileClaim.bankingForm.xDigitsValidation' }, { n: '5-16' })),
    confirm_account_number: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .min(5, intl.formatMessage({ id: 'fileClaim.bankingForm.xDigitsValidation' }, { n: '5-16' }))
      .max(16, intl.formatMessage({ id: 'fileClaim.bankingForm.xDigitsValidation' }, { n: '5-16' })),
  });
  const defaultValues = {
    account_type: '',
    routing_number: '',
    confirm_routing_number: '',
    account_number: '',
    confirm_account_number: '',
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={defaultValues}
      onSubmit={async ({ account_number, routing_number, account_type }) => {
        setSubmitting(true);
        submitDirectDepositMutation.mutate(
          { account_number, routing_number, account_type },
          {
            onSuccess: () => {
              setPaymentInfoData({ account_number, routing_number, account_type, payment_type: 'direct_deposit' });
              setSubmitting(false);
              setOpen(false);
              queryClient.invalidateQueries(['getBankingInfo']);
            },
            onError: () => {
              enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
              setSubmitting(false);
            },
          }
        );
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => {
        return (
          <form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
            {/* Account Type */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 700 }}>
                {intl.formatMessage({ id: 'fileClaim.bankingForm.accountType' })}
                <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                  *
                </Typography>
              </Typography>
              <FormControl>
                <RadioGroup name="account_type" value={values.account_type} required onChange={handleChange}>
                  <Box display={'flex'} sx={{ justifyContent: 'flex-start' }}>
                    <Box sx={{ position: 'relative' }}>
                      <FormControlLabel
                        value="checking"
                        control={<Radio />}
                        label={intl.formatMessage({ id: 'fileClaim.bankingForm.checking' })}
                        className="body1"
                      />
                      <FormControlLabel
                        value="savings"
                        control={<Radio />}
                        label={intl.formatMessage({ id: 'fileClaim.bankingForm.savings' })}
                        className="body1"
                      />
                      {touched.account_type && errors.account_type && (
                        <FormHelperText sx={{ color: 'error.main' }}>{errors.account_type}</FormHelperText>
                      )}
                    </Box>
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
            {/* Routing number */}
            <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 700 }}>
                {intl.formatMessage({ id: 'fileClaim.bankingForm.routingNumber' })}
                <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                  *
                </Typography>
              </Typography>
              <TextField
                sx={{
                  '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                  '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                }}
                disabled={Boolean(validatedBankName)}
                error={touched.routing_number && Boolean(errors.routing_number)}
                fullWidth
                helperText={touched.routing_number && errors.routing_number}
                name="routing_number"
                placeholder={intl.formatMessage({ id: 'fileClaim.bankingForm.xDigits' }, { n: 9 })}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  if (e.target.value.length > 9) {
                    return;
                  }
                  handleChange(e);
                }}
                variant="filled"
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      opacity: 0.7,
                    },
                  },
                  maxLength: 9,
                }}
                value={values.routing_number ?? ''}
              />
            </Box>
            {/* Confirm routing number */}
            <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 700 }}>
                {intl.formatMessage({ id: 'fileClaim.bankingForm.routingNumberConfirm' })}
                <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                  *
                </Typography>
              </Typography>
              <TextField
                sx={{
                  '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                  '.MuiInputBase-adornedEnd:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                }}
                disabled={Boolean(validatedBankName)}
                error={touched.confirm_routing_number && Boolean(errors.confirm_routing_number)}
                fullWidth
                helperText={touched.confirm_routing_number && errors.confirm_routing_number}
                name="confirm_routing_number"
                placeholder={intl.formatMessage({ id: 'fileClaim.bankingForm.xDigits' }, { n: 9 })}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  if (e.target.value.length > 9) {
                    return;
                  }
                  handleChange(e);
                }}
                variant="filled"
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      opacity: 0.7,
                    },
                  },
                }}
                value={values.confirm_routing_number ?? ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {(values.routing_number || values.confirm_routing_number) &&
                        values.routing_number !== values.confirm_routing_number && (
                          <CancelIcon sx={{ fontSize: 22, color: 'error.main' }} />
                        )}
                      {(values.routing_number || values.confirm_routing_number) &&
                        values.routing_number === values.confirm_routing_number && (
                          <CheckCircleIcon sx={{ fontSize: 22, color: 'success.main' }} />
                        )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* Display when routing number couldn't be validated */}
            {routingNumberInvalid && (
              <Typography sx={{ color: 'error.main' }}>
                {intl.formatMessage(
                  { id: 'fileClaim.bankingForm.invalidRoutingNumber' },
                  {
                    number: (
                      <Typography component="a" href="tel:+1-844-908-2178" sx={{ fontWeight: 500 }}>
                        +1-844-908-2178
                      </Typography>
                    ),
                  }
                )}
              </Typography>
            )}

            {/* Display when the routing number is valid */}
            {validatedBankName && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: { xs: 'flex-start', md: 'center' },
                }}
              >
                <Typography>{validatedBankName}</Typography>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ mt: { xs: 1, md: 0 }, ml: { xs: 0, md: 1 } }}
                  onClick={() => {
                    setValidatedBankName('');
                  }}
                >
                  {intl.formatMessage({ id: 'fileClaim.bankingForm.editRoutingNumber' })}
                </Button>
              </Box>
            )}

            {/* Account Number */}
            {validatedBankName ? (
              <>
                <Box sx={{ display: 'flex', mt: 2.5, mb: 1, flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({ id: 'fileClaim.bankingForm.accountNumber' })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    sx={{
                      '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                      '.MuiInputBase-adornedEnd:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none',
                      },
                    }}
                    error={touched.account_number && Boolean(errors.account_number)}
                    fullWidth
                    helperText={touched.account_number && errors.account_number}
                    name="account_number"
                    type={accType}
                    placeholder={intl.formatMessage({ id: 'fileClaim.bankingForm.xDigits' }, { n: '5-16' })}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    variant="filled"
                    inputProps={{
                      sx: {
                        '&::placeholder': {
                          opacity: 0.7,
                        },
                      },
                      maxLength: 16,
                    }}
                    value={values.account_number ?? ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={intl.formatMessage({
                              id: 'fileClaim.bankingForm.accessibility.showHideConfimAcc',
                            })}
                            onClick={() => setAccType(accType === 'password' ? 'text' : 'password')}
                          >
                            {accType === 'password' ? (
                              <VisibilityIcon sx={{ fontSize: 22 }} />
                            ) : (
                              <VisibilityOffIcon sx={{ fontSize: 22 }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({ id: 'fileClaim.bankingForm.accountNumberConfirm' })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    sx={{
                      '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                      '.MuiInputBase-adornedEnd:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none',
                      },
                    }}
                    error={touched.confirm_account_number && Boolean(errors.confirm_account_number)}
                    fullWidth
                    helperText={touched.confirm_account_number && errors.confirm_account_number}
                    name="confirm_account_number"
                    type={confirmAccType}
                    placeholder={intl.formatMessage({ id: 'fileClaim.bankingForm.xDigits' }, { n: '5-16' })}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    variant="filled"
                    inputProps={{
                      sx: {
                        '&::placeholder': {
                          opacity: 0.7,
                        },
                      },
                      maxLength: 16,
                    }}
                    value={values.confirm_account_number ?? ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={intl.formatMessage({
                              id: 'fileClaim.bankingForm.accessibility.showHideConfimAcc',
                            })}
                            onClick={() => setConfirmAccType(confirmAccType === 'password' ? 'text' : 'password')}
                          >
                            {confirmAccType === 'password' ? (
                              <VisibilityIcon sx={{ fontSize: 22 }} />
                            ) : (
                              <VisibilityOffIcon sx={{ fontSize: 22 }} />
                            )}
                          </IconButton>
                          {(values.account_number || values.confirm_account_number) &&
                            values.account_number !== values.confirm_account_number && (
                              <CancelIcon sx={{ fontSize: 22, color: 'error.main' }} />
                            )}
                          {(values.account_number || values.confirm_account_number) &&
                            values.account_number === values.confirm_account_number && (
                              <CheckCircleIcon sx={{ fontSize: 22, color: 'success.main' }} />
                            )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </>
            ) : null}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, pb: 2.5 }}>
              <Button
                type="button"
                onClick={() => setOpen(false)}
                variant="outlined"
                disabled={submitting}
                sx={{ mr: 1 }}
              >
                {intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}
              </Button>
              {!validatedBankName ? (
                <LoadingButton
                  type="button"
                  onClick={() => {
                    setValidatingRoutingNumber(true);
                    verifyRoutingNumber({ account_type: values.account_type, routing_number: values.routing_number })
                      .then((response) => {
                        if (response?.bank_name) {
                          setValidatedBankName(response?.bank_name ? response?.bank_name : '');
                          setRoutingNumberInvalid(false);
                        } else {
                          setRoutingNumberInvalid(true);
                        }
                        setValidatingRoutingNumber(false);
                      })
                      .catch(() => {
                        setValidatingRoutingNumber(false);
                        setRoutingNumberInvalid(true);
                        setValidatedBankName('');
                      });
                  }}
                  variant="contained"
                  disabled={
                    !values.account_type ||
                    !values.routing_number ||
                    String(values.routing_number).length !== 9 ||
                    values.routing_number !== values.confirm_routing_number
                  }
                  sx={{ mr: 1 }}
                  loading={validatingRoutingNumber}
                >
                  {intl.formatMessage({ id: 'forms.onboarding.next' })}
                </LoadingButton>
              ) : null}
              {validatedBankName ? (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting || values.account_number !== values.confirm_account_number}
                >
                  {intl.formatMessage({ id: 'fileClaim.mailModal.save' })}
                </Button>
              ) : null}
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

DirectDepositEnrollForm.propTypes = {
  submitting: PropTypes.bool,
  setSubmitting: PropTypes.func,
  setOpen: PropTypes.func,
  setPaymentInfoData: PropTypes.func,
};

export function handleKeyDown(event) {
  const key = event.key;
  const isDigit = /\d/.test(key);

  const isValidKey =
    ['Delete', 'Backspace', 'Tab', 'Enter'].includes(key) ||
    (['c', 'v'].includes(key) && (event.ctrlKey || event.metaKey));

  if (!isDigit && !isValidKey) {
    event.preventDefault();
  }
}
