import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { grey } from '@mui/material/colors';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import apiRequest from 'lib/API/wrapper';

/* Utils */
import { debounce } from 'lib/performance';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import {
  setNameFilterValue,
  setCareerPathsFilterValues,
  setSkillsFilter,
  setEmployerFilter,
  setSalaryRangesFilter,
  setLocationsFilterValues,
  setSortBy,
  setSortAsc,
  setFilterOptions,
  setShowRecommendedJobs,
} from 'app/slices/jobsSlice';
import { setActivities, setStatus } from 'app/slices/workSearchSlice';
import { useInfiniteJobs, useRateJob } from 'lib/hooks/jobs';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Container,
  CircularProgress,
  Typography,
  Drawer,
  MenuItem,
  Alert,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Divider, Button, List, ListItem, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

/* UI Components */
import CustomNotification from 'components/CustomNotification';
import JobNotificationContents from 'components/JobNotificationContents';
import SkillsMultiselectFilter from 'components/SkillsMultiselectFilter';
import MultiselectFilter from 'components/MultiselectFilter';
import JobsCard from 'components/JobsCard';
import ResultsSummary from 'components/ResultsSummary';

/* Icons */
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';

const Jobs = () => {
  const {
    activeSoc,
    filterByNameValue,
    careerPathsFilterValues,
    skillsFilter,
    employerFilter,
    salaryRangesFilter,
    locationsFilterValues,
    sortBy,
    sortAsc,
    showRecommendedJobs,
    filterOptions: { careerPaths, locations, orgs, skills },
  } = useSelector((state) => state.jobs);
  const { loadingLanguage } = useSelector((state) => state.app);

  const filters = {
    filter_by_name: filterByNameValue,
    filter_by_soc: careerPathsFilterValues,
    filter_by_skills: skillsFilter,
    filter_by_org: employerFilter,
    salary_min: salaryRangesFilter.length ? salaryRangesFilter[0] : 0,
    filter_by_city: locationsFilterValues,
    sort_by: sortBy,
    sort_asc: sortAsc,
    all_jobs: !showRecommendedJobs,
  };

  const filtersQuery = useQuery({
    queryKey: ['/users/me/jobs_filters', showRecommendedJobs],
    queryFn: async ({ queryKey }) =>
      apiRequest('GET', queryKey[0], {
        params: { ...(!showRecommendedJobs ? { all_jobs: !showRecommendedJobs } : {}) },
      }),
    onSuccess: (data) => {
      dispatch(
        setFilterOptions({
          locations: [...(data.cities ? data.cities : [])].sort(),
          careerPaths: data.career_paths,
          orgs: [...(data.orgs ? data.orgs : [])].sort(),
          skills: data.skills,
        })
      );
    },
  });

  const { isSuccess, data, isFetching, isFetched, hasNextPage, fetchNextPage } = useInfiniteJobs(filters);
  const { isSuccess: isDefaultSucces, data: defaultData } = useInfiniteJobs({});

  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const rateJob = useRateJob(filters);

  const intl = useIntl();
  const debouncedSetKeywordFilter = debounce((evt) => dispatch(setNameFilterValue(evt?.target?.value)), 400);
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  useEffect(() => {
    if (isSuccess) {
      setLoadedFirstTime(true);
    }
  }, [isSuccess]);

  const worksearch_status = useSelector((state) => state.worksearch.status);
  const worksearch_activities = useSelector((state) => state.worksearch.activities);

  const dispatch = useDispatch();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const filtersActive =
    !filtersQuery.isLoading &&
    (filterByNameValue.length > 0 ||
      careerPathsFilterValues.length > 0 ||
      skillsFilter.length > 0 ||
      employerFilter.length > 0 ||
      salaryRangesFilter.length > 0 ||
      locationsFilterValues.length > 0);
  const filtersCount =
    (filterByNameValue.length > 0 ? 1 : 0) +
    [careerPathsFilterValues, skillsFilter, employerFilter, salaryRangesFilter, locationsFilterValues].reduce(
      (acc, curr) => acc + curr.length,
      0
    );

  const responses = isSuccess ? data.pages.reduce((acc, page) => acc.concat(page.jobs), []) : [];
  const summary = isSuccess ? data.pages[0] : {};

  const showMore = () => {
    trackEvent('JOBS_MORE');
    fetchNextPage();
  };

  const clearFilters = () => {
    dispatch(setNameFilterValue(''));
    dispatch(setCareerPathsFilterValues([]));
    dispatch(setSkillsFilter([]));
    dispatch(setEmployerFilter([]));
    dispatch(setSalaryRangesFilter([]));
    dispatch(setLocationsFilterValues([]));
    dispatch(setSortBy(''));
  };

  const handleApplyClick = (item) => {
    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;

    trackEvent('JOBS_GLOBAL_APPLY', item);
    if (activeSoc !== '' && item.soc === activeSoc) {
      trackEvent('JOBS_APPLY_FILTEREDBYCAREER', activeSoc);
    } else {
      trackEvent('JOBS_APPLY', activeSoc);
    }

    if (worksearch_status.worksearch_enabled) {
      return apiRequest('post', '/users/me/worksearch/', {
        data: {
          activity: {
            company_name: item.org,
            contact_phone_number: '',
            contact_email: '',
            position_applied_for: item.title,
            result_of_contact: 'Applied for Job through Arkansas LAUNCH',
            application_submission_format: 'online',
            activity_type: 'applied_for_job',
            activity_date: `${year}-${month}-${day}`,
            date_of_application: `${year}-${month}-${day}`,
            job_id: item.job_id,
            draft: true,
          },
        },
      })
        .then(({ activity, status }) => {
          dispatch(setActivities(worksearch_activities.concat(activity)));
          dispatch(setStatus(status));
          return '';
        })
        .catch(() => {
          return 'not_created';
        });
    }
  };

  const handleLikeClick = (job) => () => {
    const newRating = job.rating === 1 ? 0 : 1;
    rateJob.mutate(
      { job, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          if (newRating === 0) return;
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                <JobNotificationContents rate="like" redirect="saved-items" intl={intl} history={history} />
              </CustomNotification>
            ),
          });
        },
      }
    );

    trackEvent('JOBS_GLOBAL_LIKE', job);
    if (activeSoc !== '' && job.soc === activeSoc) {
      trackEvent('JOBS_LIKE_FILTEREDBYCAREER', activeSoc);
    } else {
      trackEvent('JOBS_LIKE', job);
    }
  };

  const handleDislikeClick = (job) => () => {
    const newRating = job.rating === -1 ? 0 : -1;

    rateJob.mutate(
      { job, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                {intl.formatMessage(
                  { id: 'ratings.dislikedItem' },
                  {
                    link: (
                      <Typography
                        component="span"
                        sx={{
                          pb: 0.5,
                          '&:active': { color: grey[100] },
                          fontSize: '0.875rem',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          fontFamily: 'inherit',
                          fontWeight: 'inherit',
                        }}
                        onClick={() => history.push(`${urlPrefix}/hidden-items`)}
                      >
                        {intl.formatMessage({ id: 'ratings.dislikedItem.here' })}
                      </Typography>
                    ),
                  }
                )}
              </CustomNotification>
            ),
          });
        },
      }
    );

    trackEvent('JOBS_GLOBAL_DISLIKE', job);
    if (activeSoc !== '' && job.soc === activeSoc) {
      trackEvent('JOBS_DISLIKE_FILTEREDBYCAREER', activeSoc);
    } else {
      trackEvent('JOBS_DISLIKE', job);
    }
  };

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'jobs.pageTitle' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOBS');
    TimeMe.startTimer();
  }, []);

  const noResults = careerPathsFilterValues.length === 0 && responses.length === 0 && isSuccess;
  const showResults = !loadingLanguage && isFetched && responses && responses.length > 0;
  const showNoResults = !loadingLanguage && isFetched && responses && responses.length === 0 && isDefaultSucces;

  const FiltersList = (
    <Box sx={{ width: 264, px: 1.5 }} role="presentation">
      <List>
        <ListItem sx={{ pt: 1.5, pb: 1, pl: 0 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'jobs.filters.filtersButton' })}</Typography>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem sx={{ px: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'jobs.filters.sortBy' })}</Typography>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            color: 'rgba(0, 0, 0, 0.87)',
            li: { width: '100%' },
          }}
        >
          <MenuItem
            onClick={() => {
              if (sortBy !== 'title') {
                dispatch(setSortBy('title'));
                dispatch(setSortAsc(true));
              } else if (sortAsc) {
                dispatch(setSortAsc(false));
              } else {
                dispatch(setSortBy(''));
              }
            }}
            selected={sortBy === 'title'}
            id="jobs-title-a-z-jobs-link"
          >
            {intl.formatMessage({ id: 'jobs.filter.sortByTitle' })} {sortBy === 'title' && !sortAsc ? '(Z-A)' : '(A-Z)'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (sortBy !== 'org') {
                dispatch(setSortBy('org'));
                dispatch(setSortAsc(true));
              } else if (sortAsc) {
                dispatch(setSortAsc(false));
              } else {
                dispatch(setSortBy(''));
              }
            }}
            selected={sortBy === 'org'}
            id="company-a-z-jobs-link"
          >
            {intl.formatMessage({ id: 'jobs.filter.sortByCompany' })} {sortBy === 'org' && !sortAsc ? '(Z-A)' : '(A-Z)'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (sortBy !== 'salary_median') {
                dispatch(setSortBy('salary_median'));
                dispatch(setSortAsc(false));
              } else if (!sortAsc) {
                dispatch(setSortAsc(true));
              } else {
                dispatch(setSortBy(''));
              }
            }}
            selected={sortBy === 'salary_median'}
            id="salary-range-h-l-jobs-link"
          >
            {intl.formatMessage({ id: 'jobs.filter.sortBySalary' })}{' '}
            {sortBy === 'salary_median' && sortAsc
              ? `(${intl.formatMessage({ id: 'jobs.filter.low' })}-${intl.formatMessage({ id: 'jobs.filter.high' })})`
              : `(${intl.formatMessage({ id: 'jobs.filter.high' })}-${intl.formatMessage({ id: 'jobs.filter.low' })})`}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (sortBy !== 'city') {
                dispatch(setSortBy('city'));
                dispatch(setSortAsc(true));
              } else if (sortAsc) {
                dispatch(setSortAsc(false));
              } else {
                dispatch(setSortBy(''));
              }
            }}
            selected={sortBy === 'city'}
            id="location-a-z-jobs-link"
          >
            {intl.formatMessage({ id: 'jobs.filter.sortByLocation' })}{' '}
            {sortBy === 'city' && !sortAsc ? '(Z-A)' : '(A-Z)'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (sortBy !== 'last_scraped') {
                dispatch(setSortBy('last_scraped'));
                dispatch(setSortAsc(false));
              } else if (!sortAsc) {
                dispatch(setSortAsc(true));
              } else {
                dispatch(setSortBy(''));
              }
            }}
            selected={sortBy === 'last_scraped'}
            id="last-update-jobs-link"
          >
            {intl.formatMessage({ id: 'jobs.filter.sortByLastUpdate' })}{' '}
            {sortBy === 'last_scraped' && sortAsc
              ? intl.formatMessage({ id: 'jobs.filter.oldest' })
              : intl.formatMessage({ id: 'jobs.filter.mostRecent' })}
          </MenuItem>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem sx={{ px: 0.5 }}>
          <FormControlLabel
            sx={{ ml: 0 }}
            labelPlacement="start"
            control={
              <Switch
                checked={showRecommendedJobs}
                onChange={() => {
                  clearFilters();
                  dispatch(setShowRecommendedJobs(!showRecommendedJobs));
                }}
              />
            }
            label={intl.formatMessage({ id: 'jobs.filters.showRecommendedJobs' })}
          />
        </ListItem>

        <ListItem sx={{ px: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'training.filters.name' })}</Typography>
        </ListItem>
        <ListItem disablePadding>
          <TextField
            defaultValue={filterByNameValue}
            onChange={debouncedSetKeywordFilter}
            sx={{ '& input': { py: '11px' } }}
            label={intl.formatMessage({ id: 'training.filters.name' })}
            id="keyword-search-job"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5 }}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'careers.filter.careers' })} ({careerPaths.length})
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'jobs.filters.careers' })}
            options={careerPaths.map((car) => ({ value: car.soc, label: car.title }))}
            value={careerPathsFilterValues}
            onChange={(newValues) => dispatch(setCareerPathsFilterValues(newValues))}
            id="career-path-job-filter-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'jobs.filters.employerLabel' })} ({orgs.length})
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'jobs.filters.employer' })}
            options={orgs.map((org) => ({ value: org, label: org }))}
            value={employerFilter}
            onChange={(newValues) => dispatch(setEmployerFilter(newValues))}
            id="employer-job-filter-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'jobs.filters.salary' })}</Typography>
        </ListItem>
        <ListItem disablePadding>
          <FormControl fullWidth sx={{ '.MuiInputBase-input': { py: '11px' } }}>
            <InputLabel id="salary-select-label">
              {intl.formatMessage({ id: 'careers.filters.salaryRange' })}
            </InputLabel>
            <Select
              labelId="salary-select-label"
              value={salaryRangesFilter}
              label={intl.formatMessage({ id: 'careers.filters.salaryRange' })}
              sx={{ width: '100%' }}
              onChange={(evt) => {
                const newValue = evt.target.value;
                dispatch(setSalaryRangesFilter(newValue ? [newValue] : []));
              }}
              id="salary-job-filter-dropdown"
            >
              <MenuItem value="">
                <em>{intl.formatMessage({ id: 'careers.filters.salary.none' })}</em>
              </MenuItem>
              <MenuItem value="10000">$10,000+</MenuItem>
              <MenuItem value="25000">$20,000+</MenuItem>
              <MenuItem value="50000">$50,000+</MenuItem>
              <MenuItem value="75000">$75,000+</MenuItem>
              <MenuItem value="100000">$100,000+</MenuItem>
              <MenuItem value="150000">$150,000+</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'training.filters.location' })} ({locations.length})
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'jobs.filters.location' })}
            options={locations.map((loc) => ({ value: loc, label: loc }))}
            value={locationsFilterValues}
            onChange={(newValues) => dispatch(setLocationsFilterValues(newValues))}
            id="location-job-filter-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'experience.drawer.skillPlural' })} ({skills.length})
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <SkillsMultiselectFilter
            value={skillsFilter}
            enabledSkills={skills}
            onChange={(newValues) => dispatch(setSkillsFilter(newValues))}
            id="skills-job-filter-dropdown"
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <Helmet>
        <script id="job-match-survey" type="text/javascript">
          {`!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"b8Op7NiaoVPftUMm","delightedStars");

          delightedStars.survey({ properties: { locale: "${intl.locale ?? 'en'}" }});`}
        </script>
      </Helmet>
      <Box
        sx={{
          mt: 6,
          mb: 2.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { md: 'flex-end' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Typography variant="h1">
          <DashboardTwoToneIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
          {noResults ? intl.formatMessage({ id: 'jobs.noJobs' }) : intl.formatMessage({ id: 'jobs.title' })}
        </Typography>
        {!loadingLanguage && loadedFirstTime && (
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: { xs: 1, md: 0 }, textTransform: 'none', fontSize: 14 }}
            onClick={() => setFiltersOpen(true)}
            startIcon={<TuneOutlinedIcon />}
          >
            <Typography id="jobs-filter-and-sort-button" color="primary.dark" sx={{ fontSize: 14, fontWeight: 500 }}>
              {intl.formatMessage({ id: 'jobs.filters.filtersButton' })}
            </Typography>

            {filtersCount > 0 ? (
              <span style={{ fontSize: 13, marginLeft: filtersCount > 0 ? 8 : 0 }}>({filtersCount})</span>
            ) : null}
          </Button>
        )}
      </Box>
      {!loadingLanguage && isFetched && (
        <ResultsSummary
          items={[
            {
              Icon: RouteOutlinedIcon,
              value: summary.num_careers,
              label: intl.formatMessage({ id: 'jobs.summary.careers' }),
              id: 'career-paths-jobs-pill',
            },
            {
              Icon: WorkOutlineOutlinedIcon,
              value: summary.num_jobs,
              label: intl.formatMessage({ id: 'jobs.summary.jobs' }),
              id: 'find-jobs-jobs-pill',
            },
          ]}
        />
      )}
      <Divider sx={{ mt: 2, mb: 3 }} />

      {(isFetching && !isFetched) || loadingLanguage || !filtersQuery.isFetched ? (
        <Box m={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
      <Grid container spacing={2}>
        {!loadingLanguage && isFetched && responses && responses.length === 0 ? (
          <Grid item xs={12} mb={1}>
            <Alert severity="warning" sx={{ mt: 1, mb: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {intl.formatMessage(
                  { id: 'careers.filters.noResults' },
                  {
                    link: (
                      <Typography
                        sx={{
                          color: 'primary.dark',
                          fontWeight: 700,
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                        onClick={clearFilters}
                        component="span"
                      >
                        {intl.formatMessage({ id: 'careers.filters.noResultsLink' })}
                      </Typography>
                    ),
                  }
                )}
              </Typography>
            </Alert>
          </Grid>
        ) : null}
        {showResults &&
          responses.map((item) => (
            <JobsCard
              key={item.guid}
              item={item}
              rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
              onButtonClick={() => handleApplyClick(item)}
              onLikeClick={handleLikeClick(item)}
              onDislikeClick={handleDislikeClick(item)}
            />
          ))}
        {showNoResults &&
          defaultData.pages[0]?.jobs
            .slice(0, 5)
            .map((item) => (
              <JobsCard
                key={item.guid}
                item={item}
                rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                onButtonClick={() => handleApplyClick(item)}
                onLikeClick={handleLikeClick(item)}
                onDislikeClick={handleDislikeClick(item)}
              />
            ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }} py={4}>
        {!(filtersActive && responses.length === 0) && hasNextPage && !loadingLanguage && isFetched && isFetching ? (
          <LoadingButton type="button" onClick={() => showMore()} variant="contained" disabled sx={{ mr: 1 }} loading>
            {intl.formatMessage({ id: 'layout.loadMore' })}
          </LoadingButton>
        ) : !loadingLanguage && isFetched && !(filtersActive && responses.length === 0) && hasNextPage ? (
          <LoadingButton type="button" onClick={() => showMore()} variant="contained" sx={{ mr: 1 }}>
            {intl.formatMessage({ id: 'layout.loadMore' })}
          </LoadingButton>
        ) : null}
      </Box>
      <Drawer anchor="right" open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        {FiltersList}
      </Drawer>
    </Container>
  );
};

Jobs.propTypes = {
  width: PropTypes.string,
};

export default Jobs;
