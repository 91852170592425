import { useInfiniteQuery, useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { getCareers, getRecommendedCareers, updateCareers } from 'lib/API/careers';

const getCareersQueryKey = (filters) => [{ entity: 'careers', ...filters }];

export const useCareers = () => {
  return useQuery({
    queryKey: ['/careers/'],
    queryFn: () => {
      return getCareers([]);
    },
  });
};

const paginatedCareersQueryFn = async ({ queryKey, pageParam = 0 }) => {
  const { filter_by_name, filter_by_tag, all_careers, filter_by_avg_salary, filter_by_skills, filter_by_reqs } =
    queryKey[0];
  const data = await getRecommendedCareers([], {
    limit: 10,
    offset: pageParam * 10,
    ...(filter_by_name && filter_by_name.length ? { filter_by_name } : {}),
    ...(filter_by_tag && filter_by_tag.length ? { filter_by_tag: filter_by_tag.join('|') } : {}),
    ...(all_careers ? { all_careers } : {}),
    ...(filter_by_avg_salary && filter_by_avg_salary.length
      ? { filter_by_avg_salary: filter_by_avg_salary.join('|') }
      : {}),
    ...(filter_by_skills && filter_by_skills.length ? { filter_by_skills: filter_by_skills.join('|') } : {}),
    ...(filter_by_reqs && filter_by_reqs.length ? { filter_by_reqs: filter_by_reqs.join('|') } : {}),
  });

  return data;
};

export const useInfiniteCareers = (filters) => {
  return useInfiniteQuery({
    queryFn: paginatedCareersQueryFn,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length < 10) {
        return undefined;
      }
      return pages.flat(1).length / 10;
    },
    queryKey: getCareersQueryKey(filters),
  });
};

export const useRateCareer = (filters) => {
  const queryClient = useQueryClient();
  const queryKey = getCareersQueryKey(filters);

  return useMutation({
    mutationFn: ({ career, rating }) => {
      return updateCareers(career, rating);
    },
    onMutate: async ({ career, rating }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousCareers = queryClient.getQueryData(queryKey);

      // Optimistically update with the new rating
      queryClient.setQueryData(queryKey, (old) => {
        const clone = structuredClone(old);
        const index = clone.pages.flat(1).findIndex((oldCareer) => oldCareer.id === career.id);

        clone.pages.flat(1)[index].rating = rating;

        return clone;
      });

      // Return a context object with the snapshotted value
      return { previousCareers };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context.previousCareers);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
