import { FormControl, FormLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Field, FieldProps } from 'formik';

interface DatePickerFieldProps {
  name: string;
  label: string;
  helperText?: string;
  disabled?: boolean;
  error?: boolean;
}

export default function DatePickerField({ name, label, helperText, disabled, error }: DatePickerFieldProps) {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl disabled={disabled} error={error}>
          <FormLabel htmlFor={field.name}>{label}</FormLabel>
          <DatePicker
            aria-labelledby={field.name}
            name={field.name}
            value={field.value ?? null}
            onChange={(value) => {
              form.setFieldValue(field.name, value);
            }}
            disabled={disabled}
            disableFuture
            slotProps={{
              textField: {
                helperText,
                id: field.name,
                error: error,
              },
            }}
          />
        </FormControl>
      )}
    </Field>
  );
}
