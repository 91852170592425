import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getSkills, getSkillsRecs, updateSkillRating } from 'lib/API/skills';

const queryKey = ['/users/me/skills/'];

export const useSkills = () => {
  return useQuery({
    queryKey: ['/skills/'],
    queryFn: () => {
      return getSkills([]);
    },
  });
};

export const useUserSkills = () => {
  return useQuery({ queryKey });
};

export const useUserRecSkills = () => {
  return useQuery({
    queryKey,
    queryFn: () => {
      return getSkillsRecs([]);
    },
  });
};

export const useRateUserSkill = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ skill, rating }) => {
      return updateSkillRating(skill, rating);
    },
    onMutate: async ({ skill, rating }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousSkills = queryClient.getQueryData(queryKey);

      // Optimistically update with the new rating
      queryClient.setQueryData(queryKey, (old) => {
        const clone = structuredClone(old) || [];

        const index = clone.findIndex((oldSkill) => oldSkill.id === skill.id);

        if (index !== -1) {
          clone[index].rating = rating;
        } else {
          clone.push({ ...skill, rating });
        }

        return clone;
      });

      // Return a context object with the snapshotted value
      return { previousSkills };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context.previousSkills);
    },
  });
};
