/* eslint-disable no-console */
import axios from 'axios';
import { pathOr } from 'ramda';

// Axios wrapper functions:
// See https://axios-http.com/docs/req_config
export const apiRequest = (method, path, params = {}) => {
  const config = {
    method: method,
    url: `${process.env.REACT_APP_API_ENDPOINT}${path}`,
    responseType: 'json',
    ...params,
  };

  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const genericApiRequest = (method, path, params = {}) => {
  const config = {
    method: method,
    url: path,
    responseType: 'json',
    ...params,
  };
  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

// Auth not required for these:
const publicAPIs = new Set(['/vp2/public/token']);

// Adds access token to header:
export const configureAuth = (getAccessToken) => {
  axios.interceptors.request.use(
    async (req) => {
      if (req.url.startsWith(process.env.REACT_APP_API_ENDPOINT) && !publicAPIs.has(req.url)) {
        const accessToken = await getAccessToken();
        if (accessToken) {
          // use custom header as work around for Safari bug
          // see https://stackoverflow.com/questions/61411520/safari-http-request-header-is-not-reflecting-on-axios-interceptors-change
          req.headers['x-access-token'] = `Bearer ${accessToken}`;
        } else {
          // No token available, so cancel API request
          return {
            ...req,
            cancelToken: new axios.CancelToken((cancel) => cancel('cancel unauthorized request 2')),
          };
        }
      }
      return req;
    },
    async (err) => {
      return Promise.reject(err);
    }
  );
};

// Adds access token to header:
export const configureAuthNew = (getAccessToken) => {
  return new Promise((resolve, reject) => {
    axios.interceptors.request.use(
      async (req) => {
        const paramToken = pathOr(false, ['token'], req);
        if (
          (req.url.startsWith(process.env.REACT_APP_API_ENDPOINT) || req.url.startsWith('/api/v1')) &&
          !publicAPIs.has(req.url)
        ) {
          const accessToken = await getAccessToken();
          if (accessToken) {
            // use custom header as work around for Safari bug
            // see https://stackoverflow.com/questions/61411520/safari-http-request-header-is-not-reflecting-on-axios-interceptors-change
            req.headers['x-access-token'] = `Bearer ${accessToken}`;
          } else {
            // No token available, so cancel API request
            return {
              ...req,
              cancelToken: new axios.CancelToken((cancel) => cancel('cancel unauthorized request')),
            };
          }
        } else if (paramToken) {
          req.headers['x-access-token'] = `Bearer ${paramToken}`;
        }
        return req;
      },
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'configureAuth: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'configureAuth: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'configureAuth: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          console.error(errorMessage);
          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('configureAuth: No response was received from the server');
          reject(new Error('configureAuth: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('configureAuth', error.message);
          reject(error);
        }
      }
    );
  });
};

// API calls:

export const getProfile = (params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/profile', { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSettings = (params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/users/me/settings', { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getJobSearchActivity = (from, to) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/profile', {
      params: {
        download: 'csv',
        feature: 'activity',
        from,
        to,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const rateItem = (id, rating, item) => {
  return new Promise((resolve, reject) => {
    apiRequest('post', '/profile', {
      data: {
        action: 'rating',
        id: id,
        rating: rating,
        item: item,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postGUID = (values, isUpdate = false) => {
  return new Promise((resolve, reject) => {
    apiRequest(isUpdate ? 'put' : 'post', isUpdate ? '/users/me/' : '/users/', {
      data: values,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postUpload = (values) => {
  return new Promise((resolve, reject) => {
    apiRequest('post', '/users/me/resume/', {
      data: values,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// TODO: Fix circular dependency issue in order to delete this file
export const apiRequestV1 = () => {};
// TODO: Fix circular dependency issue in order to delete this file
export const postChat = () => {};
// TODO: Fix circular dependency issue in order to delete this file
export const postProfile = () => {};
// TODO: Fix circular dependency issue in order to delete this file
export const askJobCoach = () => {};
