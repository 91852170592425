export const formatTimestamp = (date) => {
  return (
    date.getUTCFullYear() +
    ('0' + (date.getUTCMonth() + 1)).slice(-2) +
    ('0' + date.getUTCDate()).slice(-2) +
    ('0' + date.getUTCHours()).slice(-2) +
    ('0' + date.getUTCMinutes()).slice(-2)
  );
};

// Create a new Date object without considering timezone offset
export const getUtcDate = (inputDate) => {
  return new Date(
    new Date(inputDate).getUTCFullYear(),
    new Date(inputDate).getUTCMonth(),
    new Date(inputDate).getUTCDate()
  );
};

export const passwordTests = {
  lowercase: /.*[a-z]+.*/,
  uppercase: /.*[A-Z]+.*/,
  // Special character list from:
  // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
  special: /.*[\^$*.\[\]{}\(\)?"!@#%&/\\,><\':;|_~`=+\-]+.*/, //eslint-disable-line no-useless-escape
  digit: /.*[\d]+.*/,
  length: 8,
  nospaces: /^\S+$/,
};

/**
 * Covert bytes to a larger size
 * Usage: In the upload file field
 *
 * @param {*} bytes
 * @param {*} decimals
 */
export const bytesToSize = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Formats a given phone number string into a standard format: (XXX) XXX-XXXX.
 *
 * @param {string} phoneNumberString - The phone number input as a string
 * @returns {string|null} - The formatted phone number as a string if valid, or `null` if the input does not match the expected format.
 *
 * @example
 * formatPhoneNumber("1234567890"); // "(123) 456-7890"
 * formatPhoneNumber("123456"); // null
 */
export function formatPhoneNumber(phoneNumberString) {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
}

export const getTodayMmDdYyyy = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return yyyy + '-' + mm + '-' + dd;
};

export function preventSubmitOnEnter(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export const trainingsDurationTextFormatter = (intl, d) => {
  // For NJ
  const valueMap = {
    0.14: `${intl.formatMessage({ id: 'training.duration.lessThan' })} a ${intl.formatMessage({
      id: 'training.duration.day',
    })}`,
    0.21: `1-2 ${intl.formatMessage({ id: 'training.duration.days' })}`,
    0.71: `3-7 ${intl.formatMessage({ id: 'training.duration.days' })}`,
    3: `2-3 ${intl.formatMessage({ id: 'training.duration.weeks' })}`,
    10: `4-11 ${intl.formatMessage({ id: 'training.duration.weeks' })}`,
    15: `3-5 ${intl.formatMessage({ id: 'training.duration.months' })}`,
    35: `6-12 ${intl.formatMessage({ id: 'training.duration.months' })}`,
    100: `13 ${intl.formatMessage({ id: 'training.duration.months' })} - 2 ${intl.formatMessage({
      id: 'training.duration.years',
    })}`,
    200: `3-4 ${intl.formatMessage({ id: 'training.duration.years' })}`,
    300: `${intl.formatMessage({ id: 'training.duration.moreThan' })} 4 ${intl.formatMessage({
      id: 'training.duration.years',
    })}`,
  };

  if (!d) {
    return '';
  }
  if (typeof d === 'string' || typeof d === 'number') {
    if (d === 'varies') {
      return intl.formatMessage({ id: 'training.varies' });
    }
    // in case HI data comes through here (`${num} weeks`)
    if (d > 3) {
      const newDuration = Math.floor(d / 4);
      return `${newDuration} ${intl.formatMessage({
        id: `training.duration.${newDuration === 1 ? 'month' : 'months'}`,
      })}`;
    }
    if (d <= 1) {
      return `<1 ${intl.formatMessage({ id: `training.duration.week` })}`;
    }
    if (typeof d === 'string' && d.includes('-')) {
      const [min, max] = d.split('-');
      const newMin = Math.floor(min / 4);
      const newMax = Math.floor(max / 4);
      return `${newMin}-${newMax} ${intl.formatMessage({ id: 'training.duration.months' })}`;
    }
    return `${d} ${intl.formatMessage({ id: `training.duration.weeks` })}`;
  } else {
    return valueMap[Object.keys(valueMap).find((key) => key >= d.min)] || valueMap[300];
  }
};
