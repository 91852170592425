import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const environment = process.env.REACT_APP_ENVIRONMENT;

/* UI Components */
import Loading from 'components/Loading';

export default function Login() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        ...(environment === 'prod' && { connection: 'Login-gov' }),
        ...(environment === 'uat' && { connection: 'Login-Gov-Test' }),
        prompt: 'login',
      });
    }
  }, [isAuthenticated, loginWithRedirect, isLoading]);

  return <Loading />;
}
