import apiRequest from './wrapper';

/**
 * @method : GET
 * @path   : /careers/
 * @desc   : get careers
 */
export const getCareers = (pathParam, params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/careers/', { params }, pathParam)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'getSkills: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'getSkills: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'getSkills: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          reject(new Error('getSkills: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};

/**
 * @method : GET
 * @path   : /users/me/careers/
 * @desc   : get career recs
 */
export const getRecommendedCareers = (pathParam, params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/users/me/careers/', { params }, pathParam)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'getCareers: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'getCareers: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'getCareers: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          reject(new Error('getCareers: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};

/**
 * @method : PUT
 * @path   : /users/me/careers/{soc}
 * @desc   : update career rating
 */
export const updateCareers = (career, rating) => {
  return new Promise((resolve, reject) => {
    apiRequest('put', `/users/me/careers/${career.soc}`, { data: { rating } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'updateCareers: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'updateCareers: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'updateCareers: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          reject(new Error('No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error);
        }
      });
  });
};
