import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';

// NOTE: Removing the intl and history params, and trying to use them
// from hooks here, breaks the app.
const JobNotificationContents = ({ rate, intl, history, redirect = 'ratings' }) => {
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const pageFirst = ['es', 'tl'].includes(intl.locale);
  return (
    <>
      {intl.formatMessage({ id: `ratings.${rate}dItem` })}&nbsp;
      {rate === 'dislike' ? (
        ''
      ) : pageFirst ? (
        <>
          <span>{intl.formatMessage({ id: 'ratings.ratingsPage' })}</span>&nbsp;
          <Typography
            component="span"
            sx={{
              pb: 0.5,
              '&:active': { color: grey[100] },
              fontSize: '0.875rem',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => history.push(`${urlPrefix}/${redirect}`)}
          >
            {intl.formatMessage({ id: 'layout.menu.savedItems' })}.
          </Typography>
        </>
      ) : (
        <>
          <Typography
            component="span"
            sx={{
              pb: 0.5,
              '&:active': { color: grey[100] },
              fontSize: '0.875rem',
              cursor: 'pointer',
              textDecoration: 'underline',
              fontFamily: 'inherit',
              fontWeight: 'inherit',
            }}
            onClick={() => history.push(`${urlPrefix}/${redirect}`)}
          >
            {intl.formatMessage({ id: 'layout.menu.savedItems' })}
          </Typography>
          &nbsp;
          <span>{intl.formatMessage({ id: 'ratings.ratingsPage' })}.</span>
        </>
      )}
    </>
  );
};

JobNotificationContents.propTypes = {
  rate: PropTypes.string,
  redirect: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    locale: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default JobNotificationContents;
