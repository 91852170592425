import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { or } from 'ramda';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { getClaimantInfo, getBankingInfo } from 'lib/API/ar-ui';

/* Material UI and other UI Dependencies */
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  useMediaQuery,
  IconButton,
  Divider,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import DepositEnrollCard from 'components/DepositEnrollCard';
import DepositInfoCard from 'components/DepositInfoCard';
import DebitCardCard from 'components/DebitCardCard';
import PaymentMethodAlert from 'components/PaymentMethodAlert';
import DirectDepositEnrollForm from 'components/forms/DirectDepositEnrollForm';
import CloseIcon from '@mui/icons-material/Close';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

import TimeMe from 'timeme.js';
import { useQuery } from '@tanstack/react-query';
import { isLessThan24HoursAgo } from 'lib/dates';

const UpdatePaymentMethod = () => {
  const containerRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);
  const [showEnroll, setShowEnroll] = useState(false);
  const [showComeBack, setShowComeBack] = useState(false);
  const [statePaymentInfo, setStatePaymentInfo] = useState({});

  const intl = useIntl();
  const isSmall = useMediaQuery('(max-width:767px)');

  const claimantInfo = useQuery({ queryFn: () => getClaimantInfo({}), queryKey: ['getClaimantInfo'], retry: false });

  const { data: paymentInfoData, isFetching } = useQuery({
    enabled: claimantInfo.isSuccess,
    queryFn: () => getBankingInfo({ byq: claimantInfo.data.Data?.BYQ }),
    queryKey: ['getBankingInfo'],
    retry: false,
  });

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'fileClaim.bankingModal.title' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('UI_FILE_A_CLAIM');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  if (isFetching || claimantInfo.isFetching) {
    return (
      <Box mt={6} mb={2.5} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  } else if (claimantInfo.isError) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 6, mb: 2.5 }}>
          <Typography variant="h1">{intl.formatMessage({ id: 'app.error' })}</Typography>
          <Typography variant="body2">{intl.formatMessage({ id: 'fileClaim.bankingForm.connectionError' })}</Typography>
        </Box>
      </Container>
    );
  }

  const updatedLesssThan24HoursAgo = isLessThan24HoursAgo(paymentInfoData?.last_updated);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 6, mb: 2.5 }}>
        <Typography variant="h1">
          <CreditCardOutlinedIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
          {intl.formatMessage({ id: 'fileClaim.bankingModal.title' })}
        </Typography>
        <Typography variant="body2">{intl.formatMessage({ id: 'payments.dwsPaymentSystem' })}</Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <PaymentMethodAlert />
      <Box mt={3} mb={3}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={11}>
            <Typography variant="h5">{intl.formatMessage({ id: 'fileClaim.bankingModal.paymentDetails' })}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2.5} sx={{ pt: 2 }}>
          <Grid item xs={12} md={4}>
            <DebitCardCard payment_type={or(statePaymentInfo.payment_type, paymentInfoData?.payment_type)} />
          </Grid>
          <Grid item xs={12} md={4}>
            {or(statePaymentInfo.payment_type, paymentInfoData?.payment_type) === 'direct_deposit' ? (
              <DepositInfoCard
                setPaymentInfoData={setStatePaymentInfo}
                updatedLesssThan24HoursAgo={updatedLesssThan24HoursAgo}
                paymentInfoData={{ ...paymentInfoData, ...statePaymentInfo }}
                setShowEnroll={updatedLesssThan24HoursAgo ? setShowComeBack : setShowEnroll}
              />
            ) : (
              <DepositEnrollCard
                paymentInfoData={{ ...paymentInfoData, ...statePaymentInfo }}
                setShowEnroll={updatedLesssThan24HoursAgo ? setShowComeBack : setShowEnroll}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Dialog open={showEnroll} onClose={() => setShowEnroll(false)} maxWidth="md" fullWidth>
        <DialogTitle variant="h4" component="h2">
          {intl.formatMessage({ id: 'fileClaim.bankingModal.title' })}
          <IconButton
            aria-label="close"
            onClick={() => setShowEnroll(false)}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent ref={containerRef} sx={{ position: 'relative', minHeight: isSmall ? 400 : 540 }}>
          <Box
            sx={{
              bgcolor: 'white',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              pt: 1,
              px: 3,
              boxSizing: 'border-box',
            }}
          >
            <DirectDepositEnrollForm
              setPaymentInfoData={setStatePaymentInfo}
              submitting={submitting}
              setSubmitting={setSubmitting}
              setOpen={setShowEnroll}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <ComeBackDialog showComeBack={showComeBack} setShowComeBack={setShowComeBack} />
    </Container>
  );
};

export function ComeBackDialog({ showComeBack, setShowComeBack }) {
  const containerRef = useRef(null);
  const intl = useIntl();
  return (
    <Dialog open={showComeBack} onClose={() => setShowComeBack(false)}>
      <DialogTitle variant="h4" component="h2">
        {intl.formatMessage({ id: 'payments.notAllowed' })}
        <IconButton
          aria-label="close"
          onClick={() => setShowComeBack(false)}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent ref={containerRef} sx={{ position: 'relative' }}>
        <DialogContentText id="alert-dialog-description">
          <Typography>{intl.formatMessage({ id: 'payments.comeBack' })}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setShowComeBack(false)} autoFocus>
          {intl.formatMessage({ id: 'layout.hamburger.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
ComeBackDialog.propTypes = {
  showComeBack: PropTypes.bool,
  setShowComeBack: PropTypes.func,
};

export default UpdatePaymentMethod;
