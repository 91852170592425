import { useEffect } from 'react';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Typography, Box, Container } from '@mui/material';
import CloseTabButton from 'components/CloseTabButton';

const TermsOfUse = () => {
  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TERMSOFUSE');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <CloseTabButton className={layoutStyles.closeButton} />
          <Typography variant="h1" gutterBottom>
            Terms of Use and Privacy Policy
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          <Typography variant="body2" component="p" gutterBottom>
            Welcome to Arkansas LAUNCH for Jobseekers.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            We are taking every precaution, including making our best efforts to comply with all relevant state and
            federal laws and regulations related to privacy, to ensure that your information is as secure as possible.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            These Terms of Use (these “<u>Terms</u>”) are posted by the Arkansas Department of Transformation and Shared
            Services (“<u>ADTSS</u>” “<u>we</u>,” “<u>us</u>,” or “<u>our</u>”) and govern your use of LAUNCH for
            Jobseekers.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            By visiting and using this website, its subpages, and content (collectively known as this website), you are
            agreeing to the Terms of Use and Privacy Policy.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Eligibility
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            By using this website, you warrant that you are 14 years of age or older and have the legal authority to
            enter into these Terms of Use. Participants aged 13 and below are strictly prohibited from using this
            website.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Adherence to State and Federal Labor Laws
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            You must adhere to all relevant state and federal laws, including, without limitation, Arkansas child labor
            laws, which regulate the working conditions and hours for minors. While minors between the ages 14 and 17
            may work under certain conditions and limitations, to better understand your rights and obligations, consult
            with the applicable rules, regulations, and requirements set forth in the Fair Labor Standards Act (FLSA) at{' '}
            <Typography
              component="a"
              target="_blank"
              href="https://www.dol.gov/agencies/whd/youthrules"
              rel="noopener noreferrer"
              sx={{
                color: (theme) => theme.palette.info.main,
                fontWeight: 700,
                wordBreak: 'break-all',
              }}
            >
              https://www.dol.gov/agencies/whd/youthrules
            </Typography>{' '}
            and the Arkansas state labor laws at{' '}
            <Typography
              component="a"
              target="_blank"
              href="https://www.labor.arkansas.gov/labor/labor-standards/child-labor/"
              rel="noopener noreferrer"
              sx={{
                color: (theme) => theme.palette.info.main,
                fontWeight: 700,
                wordBreak: 'break-all',
              }}
            >
              https://www.labor.arkansas.gov/labor/labor-standards/child-labor/
            </Typography>
            . We make no representations, warranties, or covenants with respect to decisions you make regarding
            employment or work. Nothing herein should be construed as an encouragement or endorsement for your work as a
            minor.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Use of Information
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            The Arkansas Department of Transformation and Shared Services and its agents, contractors, and partners will
            use State of Arkansas records and the information you provide when you register for LAUNCH for Jobseekers to
            connect you with personalized employment and training opportunities.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            We may also use and share the provided information as reasonably necessary to comply with law or a legal
            process (including a court or government order or subpoena); to detect, prevent, or otherwise address fraud,
            security or technical issues; to enforce the Terms of Use and Privacy Policy; to protect the rights,
            property or safety of the State of Arkansas, our users, and the public; or otherwise with your express
            consent.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            By registering for LAUNCH for Jobseekers, you agree to allow the Department and its agents, contractors, and
            partners to use the above information, as well as, any associated data you have made available to the
            Department, to create personalized job and career opportunity recommendations for you. You further agree
            that the Department and its agents, contractors, and partners may contact you or send you communications
            which may include, without limitation, important information regarding job recruitment opportunities,
            information on available jobs, skills training opportunities, education opportunities, and other
            skills-related resources and opportunities.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            By providing your cell phone number you specifically agree to allow the Department to send communications to
            your cell phone which may include important information regarding unemployment insurance programs, job
            recruitment opportunities that may be suitable for you, information on available jobs that may be suitable
            for you, and available skills training opportunities.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            You may elect to allow the Department to share your contact information directly with employers and
            recruiters who may be interested in hiring you based on your skills, interests, and experience. If you elect
            this service, you agree to allow the Department and its agents, contractors, and partners to share your
            contact information, past employment and education history, and a recommendation about your fit with a job
            or training program directly with Arkansas employers and recruitment, placement, and government agencies
            solely for the purpose of contacting you about hiring or skills training opportunities that may be a good
            fit for your skills and background.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Security and Monitoring Notice
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            If you browse through this website to read, print, or download information, navigational information may be
            collected.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            For site security purposes, all network traffic is monitored in order to identify unauthorized attempts to
            upload or change information, or otherwise conduct criminal activity. In connection with authorized law
            enforcement investigations and pursuant to required legal process, navigational information may be used to
            assist in obtaining personal information.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Further, to protect this website as well as the associated computer system supporting the website from
            unauthorized use and to ensure that the computer system is functioning properly, individuals accessing this
            website and the associated computer system are subject to having all of their activities monitored and
            recorded by personnel authorized to do so by the State of Arkansas. Anyone using this website and the
            associated computer system expressly consents to such monitoring and is advised that if such monitoring
            reveals evidence of possible abuse or criminal activity, State of Arkansas personnel may provide the results
            of such monitoring to appropriate officials. Unauthorized attempts to upload or change the content of the
            website, or otherwise cause damage to this website or the computer system are strictly prohibited and may be
            punishable under applicable law.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Change Without Notice
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            This website is dynamic and may change over time without notice. Users of this website are therefore fully
            responsible for checking the accuracy, completeness, currency, and suitability of content found on the
            website themselves.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            In addition, we reserve the right to modify the Terms of Use and Privacy Policy at any time. We encourage
            you to review the Terms of Use and Privacy Policy on a regular basis. Your continued use of LAUNCH for
            Jobseekers following any modifications to the website and the Terms of Use and Privacy Policy constitutes
            your agreement to such modifications.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Disclaimer of Warranties
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            This website is provided “AS IS” and without warranties of any kind. To the fullest extent of the law, the
            State of Arkansas, including each agency, officer, or employee of the State of Arkansas disclaims all
            warranties, expressed or implied, including but not limited to warranties of merchantability and fitness for
            a particular purpose, with respect to this website; this disclaimer of warranties includes but is not
            limited to disclaimers as regards the following: (1) that the functioning of this website will be
            uninterrupted and error-free; (2) that any defects or other errors on this website will be corrected; and
            (3) that this website as well as the computer system are or will be free of viruses or other harmful
            components.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            In addition, neither the State of Arkansas nor any agency, officer, or employee of the State of Arkansas
            makes any representations, guarantees, or warranties as to: (1) the accuracy, completeness, currency, or
            suitability of the information provided via this website; (2) the use of or the results of the use of this
            website; and (3) the accuracy, reliability, availability or completeness or usefulness of the content of
            websites created and maintained by persons other than the State of Arkansas and linked to or from this
            website.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Limitation of Liability
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Under no circumstances, including but not limited to, negligence, shall the State of Arkansas nor any
            agency, officer, or employee of the State of Arkansas be liable for any special, consequential and/or
            incidental damages that may result from the use of or the inability to access or use this website, even if
            the State of Arkansas has been advised of the possibility of such damages. Applicable law may not allow the
            limitation or exclusion of liability or incidental or consequential damages, so the above limitation or
            exclusion may not apply to you. In no event shall the State of Arkansas’ total liability to you for any and
            all damages, losses, and causes of action (whether in contract, tort (including but not limited to
            negligence, or otherwise) exceed the amount paid by you, if any, to access this website.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            THIS LIMITATION OF LIABILITY SHALL SURVIVE THE TERMINATION OF THIS AGREEMENT. THE FOREGOING LIMITATION OF
            LIABILITY SHALL APPLY TO ALL PARTIES ACTING ON BEHALF OF ADTSS, INCLUDING ITS EMPLOYEES, AGENTS, AND
            CONTRACTORS.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Copyright Notice
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Duplication or use of any content from this website for commercial purposes or in any manner likely to give
            the impression of official approval by the State of Arkansas is prohibited.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Links to External Websites and Pages
          </Typography>
          <Typography variant="body2" component="div" gutterBottom>
            <p>
              This website may contain hypertext links to external sites and pages containing information created and
              maintained by public and private organizations other than the State of Arkansas. These hypertext links may
              be created by the State of Arkansas if it determines that establishing the external link will be
              consistent with assisting or furthering the purpose of this website which is to significantly and
              diligently promote public service to citizens and businesses by:
            </p>
            <ul>
              <li>expanding business and citizen access to government services and government information; </li>
              <li>
                offering an easy and convenient process for businesses and citizens to conduct transactions with State
                government online;
              </li>
              <li>
                accelerating the development and delivery of an increased volume of quality, online government services;
              </li>
              <li>improving the level of customer service from State government; and </li>
              <li>extending electronic government services to citizens of cities and county government. </li>
            </ul>
            <p>
              In addition, hypertext links may be created by the State of Arkansas for informational purposes, that is,
              where the linked external website will provide useful and valuable information to visitors to this
              website, or where the linked external website is required or authorized by law.
            </p>
            <p>
              The State of Arkansas in its sole discretion, will determine whether the external website meets the
              purpose of this website or for the specified informational purposes. The inclusion of a hypertext link to
              an external website is not intended as an endorsement of any products or service offered or referenced on
              the linked website, the organizations sponsoring such website, or any views which might be expressed or
              referenced on the website.
            </p>
            <p>
              These hypertext links to external sites and pages may be removed or replaced in the sole discretion of the
              State of Arkansas, at any time without notice.
            </p>
            <p>
              In the event you discover problems with or have concerns regarding the format, accuracy, timeliness, or
              completeness of a linked external site, please contact the organization responsible for the linked
              external site – the State of Arkansas does not control nor is it responsible for any linked external sites
              and pages.
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Disclaimer of Endorsement
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Any reference in this website to any specific commercial products, processes, or services by trade name,
            trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or
            favoring by the State of Arkansas.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Jurisdiction
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Any person choosing to access and use this website is subject to the jurisdiction of the State of Arkansas.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            These terms shall be governed by and construed in accordance with the laws of the State of Arkansas. Any
            dispute arising out of or relating to these terms, or access and use of this website shall be decided under
            the laws of and in the state courts in the State of Arkansas, and you hereby consent to and submit to the
            personal jurisdiction of such courts for the purpose of adjudicating such dispute.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Nothing in this Terms of Use and Privacy Policy shall be construed to waive the Sovereign Immunity of the
            STATE OF ARKANSAS or any entities thereof.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Other Websites
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            This website may contain hypertext links to other websites and pages containing information created and
            maintained by other public and private organizations. If you decide to visit these linked sites, you should
            check the privacy statements of these other websites. The State of Arkansas is not responsible for the
            content provided on these other websites.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Severability
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            If any provision in the above Terms of Use and Privacy Policy shall be determined to be unlawful, void, or
            unenforceable by a court of competent jurisdiction, then that provision shall be deemed severable from the
            remaining terms and shall not affect the validity and enforceability of the remaining provisions.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Unemployment Fraud:
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              If you make a false statement or fail to give important facts in order to be paid benefits, that is
              considered fraud. ADWS takes benefits fraud seriously.
            </p>
            <ul>
              <li>
                If you are assessed fraud of $1,000 or more, you will be:
                <ul>
                  <li>Disqualified from receiving benefits for ten (10) years,</li>
                  <li>Assessed a 50% fraud penalty,</li>
                  <li>
                    Required to pay back all benefits plus penalty, interest, and fees before receiving any future
                    benefits.
                  </li>
                </ul>
              </li>
              <li>
                If you are assessed fraud for less than $1,000, you will be:
                <ul>
                  <li>Assessed a 50% fraud penalty,</li>
                  <li>Benefits will be reduced to zero.</li>
                  <li>
                    Required to pay back all benefits plus penalty, interest, and fees before receiving any future
                    benefits.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Work Search Requirements:
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              You will be required to make five (5) job contacts per week. The contacts must be made during the week you
              are claiming in order to count as job contacts for that week. These contacts must be recorded for audit
              purposes. A “work search contact” includes the following actions if they are made for the purpose of
              finding a job:
            </p>
            <ul>
              <li>Completing and submitting an application or resume to an employer,</li>
              <li>Attending an interview with an employer,</li>
              <li>Attending a job fair; or</li>
              <li>
                Attending a training for job skills at a workforce center or training for a job that is in demand with
                an approved employer.
              </li>
            </ul>
            <p>
              ***If you do not show up for two (2) scheduled job interviews with prospective employers and do not have
              good cause or give the employer notice, you will be disqualified from receiving benefits until you have
              thirty (30) days of covered work.***
            </p>
          </Typography>
        </Container>
      </div>
    </>
  );
};

export default TermsOfUse;
