import { useState } from 'react';
import PropTypes from 'prop-types';
import { or } from 'ramda';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  lighten,
  Typography,
} from '@mui/material';
import PDFViewer from 'components/resume-builder/PDFViewer';
import EnDisclosure from 'assets/documents/english-disclosure.pdf';
import EsDisclosure from 'assets/documents/spanish-disclosure.pdf';

/* API */
import { submitDebidCard } from 'lib/API/ar-ui';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';
import { grey } from '@mui/material/colors';

/* Icons */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

export default function DepositInfoCard({
  setShowEnroll,
  updatedLesssThan24HoursAgo,
  setPaymentInfoData,
  paymentInfoData: { account_type, routing_number, account_number },
}) {
  const intl = useIntl();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [contentsAccepted, setContentsAccepted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = intl.locale === 'es' ? EsDisclosure : EnDisclosure;
    link.download = `Disclosure-${intl.locale === 'es' ? 'es' : 'en'}.pdf`;
    link.click();
  };

  const headerContent = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex' }}>
        <CheckCircleIcon sx={{ color: 'success.main', fontSize: 22 }} />{' '}
        <Typography sx={{ color: 'success.main', fontWeight: 700, pl: 0.5 }}>
          {intl.formatMessage({
            id: 'fileClaim.bankingModal.currentMethod',
          })}
        </Typography>
      </Box>

      <Button
        size="small"
        onClick={() => (updatedLesssThan24HoursAgo ? setShowEnroll(true) : setDeleteDialogOpen(true))}
      >
        {intl.formatMessage({ id: 'experience.remove' })}
      </Button>
    </Box>
  );

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          borderStyle: 'solid',
          borderColor: grey['300'],
          height: '100%',
        }}
      >
        <CardHeader
          title={headerContent}
          sx={{ py: 1, bgcolor: (theme) => lighten(theme.palette.success.main, 0.95) }}
        />
        <CardContent sx={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: grey['300'] }}>
          <Typography variant="h3" sx={{ textAlign: 'center', mb: 1.5 }}>
            {intl.formatMessage({ id: 'fileClaim.bankingModal.directDeposit' })}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
            {intl.formatMessage({ id: 'fileClaim.bankingForm.accountType' })}:{' '}
            <Typography component="span" sx={{ textTransform: 'capitalize', fontWeight: 700 }}>
              {or(account_type, '****')}
            </Typography>
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
            {intl.formatMessage({ id: 'fileClaim.bankingForm.routingNumber' })}:{' '}
            <Typography component="span" sx={{ fontWeight: 700 }}>
              {or(routing_number, '****')}
            </Typography>
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
            {intl.formatMessage({ id: 'fileClaim.bankingForm.accountNumber' })}:{' '}
            <Typography component="span" sx={{ fontWeight: 700 }}>
              *{account_number ? account_number.slice(-4) : '***'}
            </Typography>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button onClick={() => setShowEnroll(true)} variant="contained">
              {intl.formatMessage({ id: 'fileClaim.bankingForm.changeBank' })}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={deleteDialogOpen} onClose={() => {}} maxWidth="md" fullWidth>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {intl.formatMessage({ id: 'experience.row.delete' })}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              alignItems: { xs: 'flex-start', md: 'center' },
              mb: 1,
            }}
          >
            <DialogContentText component="div">
              <FormattedMessage
                id="fileClaim.deleteDialog.text"
                values={{
                  p: (chunks) => <Box>{chunks}</Box>,
                }}
              />
            </DialogContentText>
            <Button onClick={handleDownload} variant="outlined" sx={{ mt: 1, mb: { xs: 1, md: 2 } }}>
              {intl.formatMessage({ id: 'fileClaim.deleteDialog.downloadPdf' })}
            </Button>
          </Box>
          <PDFViewer file={intl.locale === 'es' ? EsDisclosure : EnDisclosure} handleChangePdfUrl={() => {}} />
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { md: 'space-between' },
            px: 3,
            pb: 1,
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={contentsAccepted}
                  onChange={(e) => {
                    setContentsAccepted(e.currentTarget.checked);
                  }}
                />
              }
              label={intl.formatMessage({ id: 'uiLaws.confirm' })}
              sx={{ mt: 1 }}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              mt: { xs: 2, sm: 1, md: 0 },
              mb: { xs: 1, md: 0 },
            }}
          >
            <Button
              variant="outlined"
              autoFocus
              onClick={() => setDeleteDialogOpen(false)}
              id="delete-go-back-payment-method-button"
            >
              {intl.formatMessage({ id: 'jobSearchActivity.deletePopup.cancel' })}
            </Button>
            <Button
              variant="contained"
              id="delete-yes-experience-button"
              onClick={() => {
                setSubmitting(true);
                submitDebidCard({})
                  .then(() => {
                    setSubmitting(false);
                    setDeleteDialogOpen(false);
                    setPaymentInfoData({ payment_type: 'debit_card' });
                    queryClient.invalidateQueries(['getBankingInfo']);
                  })
                  .catch(() => {
                    setSubmitting(false);
                    enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
                  });
              }}
              sx={{ ml: 1 }}
              disabled={!contentsAccepted || submitting}
            >
              {intl.formatMessage({ id: 'jobSearchActivity.deletePopup.continue' })}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

DepositInfoCard.propTypes = {
  paymentInfoData: PropTypes.shape({
    account_number: PropTypes.string,
    routing_number: PropTypes.string,
    account_type: PropTypes.string,
    payment_type: PropTypes.string,
  }),
  setShowEnroll: PropTypes.func,
  setPaymentInfoData: PropTypes.func,
  updatedLesssThan24HoursAgo: PropTypes.bool,
};
